export const CONFIG_SLOT = {
    default: {
        color: "#e8ebfa",
    },
    saved_or_sent: {
        color: "#EBEBEB",
    },
    validated: {
        color: "#E7F2DA",
    },
};
