import React, { useEffect } from "react";
import {
    Button,
    Dropdown,
    Flex,
    Image,
    InfoIcon,
    Input,
    InputLabel,
    Loader,
    RadioGroup,
    RadioGroupItem,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
    Container,
    ContainerFluid,
    ErrorMessage,
    LabelRequired,
} from "../../../common/styles";
import {
    I18N_NS_EXPENSE,
    TYPE_ENERGY,
    TYPE_FORM,
    TYPE_VEHICULE,
    VALIDATORS_ADD_EXPENSE_KM_FR,
} from "../constants";
import { Controller, set, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_add_expense_km_ch,
    _api_add_expense_km_fr,
    _api_get_bareme,
} from "../api";
import { ROUTES } from "../../../constants/routes";
import { TextCategory } from "./ExpensesAddFormClassic";
import CategoryExpense from "./CategoryExpense/CategoryExpense";
import { DEVICE } from "../../../constants/responsive";

const ExpensesAddFormKmFr = () => {
    const { t } = useTranslation(I18N_NS_EXPENSE);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const {
        state: { default_mileage_allowance_rate, category },
    } = useLocation();

    const {
        id_category: param_id_category,
        id_expense: param_id_expense,
        type_form: param_type_form,
    } = useParams();

    const path_expense = ROUTES.expenses.home;
    const path_detail = ROUTES.expenses.detail;

    const _goback = () => {
        return navigate(-1);
    };

    const { mutate, isLoading: isMutating } = useMutation((data) => {
        const {
            arrival,
            comment,
            date,
            departure,
            distance,
            title,
            power,
            energy,
            type_vehicule,
        } = data;
        return _api_add_expense_km_fr({
            param_id_expense: param_id_expense,
            name: title,
            expense_category_id: parseInt(param_id_category),
            expense_date: date,
            comment: comment,
            rate: 1,
            custom_fields: [
                {
                    code: "departure-place",
                    value: departure,
                },
                {
                    code: "arrival-place",
                    value: arrival,
                },
                {
                    code: "distance",
                    value: parseFloat(distance),
                },
                {
                    code: "vehicle-power-type",
                    value: energy?.value,
                },
                {
                    code: "vehicle-type",
                    value: type_vehicule?.value,
                },
                {
                    code: "power-value",
                    value: parseInt(power?.value),
                },
            ],
        });
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useForm({
        mode: "onChange",
        defaultValues: {
            title: "",
            type_vehicule: TYPE_VEHICULE[0],
            energy: TYPE_ENERGY[0],
            power: null,
        },
    });

    /**
     * submit add expense
     */
    const onSubmit = (data) => {
        mutate(data, {
            onSuccess: (data) => {
                if (data?.success) {
                    const path_detail_params = path_detail.custom_route(
                        path_detail.route,
                        param_id_expense
                    );
                    return navigate(
                        path_expense +
                            "/" +
                            path_detail_params +
                            "?toast=expense_added"
                    );
                } else {
                    alert("error onsubmit");
                }
            },
        });
        return;
    };

    const _watch = watch();

    const { data, isLoading, isFetching, refetch } = useQuery(
        [
            "timeline::getBarem",
            {
                power_type: _watch?.energy?.value,
                vehicle_type: _watch?.type_vehicule?.value,
                power_value: _watch?.power?.value ?? "",
            },
        ],
        () =>
            _api_get_bareme({
                power_type: _watch?.energy?.value,
                vehicle_type: _watch?.type_vehicule?.value,
                distance: _watch?.distance,
                power_value: _watch?.power?.value ?? "",
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <ContainerFluid>
            <Container gap="gap.large" style={{ marginTop: "24px" }}>
                <Wrapper fill column gap="gap.medium">
                    <Flex fill>
                        <HeaderTitle gap="gap.large">
                            <TitlePage>{t("forms.km_fr.title")}</TitlePage>

                            {/* show/update category */}
                            <CategoryExpense
                                state={{
                                    category: {
                                        icon: category?.icon,
                                        name: category?.name,
                                    },
                                }}
                            />
                        </HeaderTitle>
                        <Button
                            text
                            primary
                            content={t("common.cancel")}
                            onClick={_goback}
                        />
                    </Flex>
                    <WhiteBoard fill column gap="gap.large">
                        <Flex
                            column
                            gap="gap.large"
                            as="form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Flex>
                                <CardTitle
                                    content={t("forms.km_fr.card_title")}
                                />
                            </Flex>
                            <Flex column gap="gap.medium">
                                <Flex gap="gap.large">
                                    <Flex column>
                                        <Input
                                            label={
                                                <Flex>
                                                    <Text
                                                        content={t(
                                                            "forms.km_fr.date.label"
                                                        )}
                                                    />
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </Flex>
                                            }
                                            type="date"
                                            {...register(
                                                "date",
                                                VALIDATORS_ADD_EXPENSE_KM_FR.date
                                            )}
                                            error={!!errors?.date?.message}
                                            fluid
                                            styles={{
                                                width: "212px",
                                            }}
                                        />
                                        <ErrorMessage
                                            content={t(errors?.date?.message)}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex fill gap="gap.medium">
                                    <Flex column fill>
                                        <Input
                                            label={t(
                                                "forms.km_fr.departure.label"
                                            )}
                                            {...register("departure")}
                                            clearable
                                            fluid
                                            error={!!errors?.departure?.message}
                                        />
                                        <ErrorMessage
                                            content={t(
                                                errors?.departure?.message
                                            )}
                                        />
                                    </Flex>
                                    <Flex column fill>
                                        <Input
                                            label={t(
                                                "forms.km_fr.arrival.label"
                                            )}
                                            {...register("arrival")}
                                            clearable
                                            fluid
                                            error={!!errors?.arrival?.message}
                                        />
                                        <ErrorMessage
                                            content={t(
                                                errors?.arrival?.message
                                            )}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex vAlign="end" column>
                                    <Flex vAlign="end">
                                        <Input
                                            label={
                                                <>
                                                    {t(
                                                        "forms.km_fr.distance.label"
                                                    )}
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </>
                                            }
                                            type="number"
                                            {...register(
                                                "distance",
                                                VALIDATORS_ADD_EXPENSE_KM_FR.distance
                                            )}
                                            error={!!errors?.distance?.message}
                                            clearable
                                            errorIndicator={false}
                                            fluid
                                            styles={{
                                                borderRadius:
                                                    "4px 0px 0px 4px!important",
                                                width: "212px",
                                            }}
                                            onBlur={() => refetch()}
                                        />
                                        <Button
                                            disabled
                                            content={
                                                <Text
                                                    styles={{
                                                        color: "#242424",
                                                    }}
                                                    content={"Km"}
                                                />
                                            }
                                            flat
                                            fluid
                                            styles={{
                                                borderRadius: "0px 4px 4px 0px",
                                                width: "fit-content!important",
                                                backgroundColor: "#C7C7C7",
                                                padding: 0,
                                                minWidth: "36px",
                                                // width: "40px";
                                            }}
                                        />
                                    </Flex>
                                    <Flex>
                                        <ErrorMessage
                                            content={t(
                                                errors?.distance?.message
                                            )}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex column>
                                        <InputLabel
                                            content={
                                                <>
                                                    {t(
                                                        "forms.km_fr.vehicule.type"
                                                    )}
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </>
                                            }
                                        />
                                        <RadioGroupStyle
                                            {...register("type_vehicule")}
                                            items={TYPE_VEHICULE.map((el) => {
                                                return {
                                                    id: el?.value,
                                                    value: el?.value,
                                                    label: (
                                                        <TextRadio
                                                            content={t(
                                                                el?.label
                                                            )}
                                                        />
                                                    ),
                                                    temp: el,
                                                };
                                            })}
                                            onCheckedValueChange={(e, i) => {
                                                setValue(
                                                    "type_vehicule",
                                                    i?.temp
                                                );
                                                setValue("power", null);
                                                return;
                                            }}
                                            defaultCheckedValue={
                                                TYPE_VEHICULE[0].value
                                            }
                                        />
                                    </Flex>
                                </Flex>
                                {/* Type energy */}
                                <Flex>
                                    <Flex column>
                                        <InputLabel
                                            content={
                                                <>
                                                    {t(
                                                        "forms.km_fr.energy.type"
                                                    )}
                                                    <LabelRequired
                                                        content={"*"}
                                                    />
                                                </>
                                            }
                                        />
                                        <RadioGroupStyle
                                            items={TYPE_ENERGY?.map((el) => {
                                                return {
                                                    id: el?.value,
                                                    value: el?.value,
                                                    label: (
                                                        <TextRadio
                                                            content={t(
                                                                el?.label
                                                            )}
                                                        />
                                                    ),
                                                    temp: el,
                                                };
                                            })}
                                            onCheckedValueChange={(e, i) => {
                                                setValue("energy", i?.temp);
                                                return;
                                            }}
                                            defaultCheckedValue="therm"
                                        />
                                    </Flex>
                                </Flex>
                                <VehiculePower fill gap="gap.medium">
                                    {_watch?.type_vehicule?.dropdown_power
                                        ?.show && (
                                        <Flex column>
                                            <InputLabel
                                                content={
                                                    <>
                                                        {" "}
                                                        <TextLabel
                                                            content={t(
                                                                "forms.km_fr.power.label"
                                                            )}
                                                        />
                                                        <LabelRequired
                                                            content={"*"}
                                                        />
                                                    </>
                                                }
                                            />
                                            <Flex as="div" column>
                                                <Controller
                                                    control={control}
                                                    name="power"
                                                    rules={
                                                        VALIDATORS_ADD_EXPENSE_KM_FR.power
                                                    }
                                                    defaultValue={
                                                        _watch?.type_vehicule
                                                            ?.dropdown_power
                                                            ?.values[0]
                                                    }
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                            onBlur,
                                                            ref,
                                                            name,
                                                        },
                                                    }) => (
                                                        <Dropdown
                                                            fluid
                                                            items={
                                                                _watch
                                                                    ?.type_vehicule
                                                                    ?.dropdown_power
                                                                    ?.values
                                                            }
                                                            // loading={isLoadingVats}
                                                            loadingMessage={
                                                                "chargement"
                                                            }
                                                            onChange={(
                                                                e,
                                                                i
                                                            ) => {
                                                                onChange(
                                                                    i?.value
                                                                );
                                                                // setValue("power", i?.value?.value);
                                                                return;
                                                            }}
                                                            // value={value?.header}
                                                            error={
                                                                errors?.power
                                                                    ?.message
                                                            }
                                                            name={name}
                                                            ref={ref}
                                                            clearable
                                                            value={value}
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    content={t(
                                                        errors?.power?.message
                                                    )}
                                                />
                                            </Flex>
                                        </Flex>
                                    )}
                                    <Flex column>
                                        <InputLabel
                                            content={
                                                <Text>
                                                    {t(
                                                        "forms.km_fr.bareme.label"
                                                    )}
                                                    <InfoIcon
                                                        outline
                                                        size="small"
                                                        styles={{
                                                            marginLeft: "4px",
                                                        }}
                                                    />
                                                </Text>
                                            }
                                        />
                                        <BaremeKm>
                                            {isLoading || isFetching ? (
                                                <Loader size="small" />
                                            ) : (
                                                data?.value
                                            )}
                                        </BaremeKm>
                                    </Flex>
                                </VehiculePower>
                                <Flex column>
                                    <InputLabel
                                        content={t("forms.km_fr.comment.label")}
                                    />
                                    <TextArea
                                        {...register(
                                            "comment",
                                            VALIDATORS_ADD_EXPENSE_KM_FR.comment
                                        )}
                                        styles={{
                                            height: "107px",
                                        }}
                                        clearable
                                        fluid
                                        error={!!errors?.comment?.message}
                                    />
                                    <ErrorMessage
                                        content={t(errors?.comment?.message)}
                                    />
                                </Flex>
                            </Flex>
                            <Flex
                                fill
                                vAlign="center"
                                hAlign="center"
                                gap="gap.medium"
                            >
                                <Button
                                    content={t("common.cancel")}
                                    flat
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return _goback();
                                    }}
                                />
                                <Button
                                    loading={isMutating}
                                    content={t("forms.km_fr.button_add")}
                                    primary
                                    flat
                                />
                            </Flex>
                        </Flex>
                    </WhiteBoard>
                </Wrapper>
            </Container>
        </ContainerFluid>
    );
};

export default ExpensesAddFormKmFr;

export const TitlePage = styled(Text)`
    color: #424242;
    font-size: clamp(18px, 2vw, 24px);
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    white-space: nowrap;
`;

export const CardTitle = styled(Text)`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
`;

const TextLabel = styled(Text)`
    font-size: clamp(12px, 2vw, 14px);
    color: #484644;
    font-style: normal;
    font-weight: 400;
    line-height: clamp(16px, 2vw, 24px);
`;

const VehiculePower = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;

const TextRadio = styled(Text)`
    font-size: clamp(12px, 2vw, 14px);
`;

const RadioGroupStyle = styled(RadioGroup)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }

    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;
const HeaderTitle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        margin-left: 16px;
    }

    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;

const BaremeKm = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const WhiteBoard = styled(Flex)`
    background-color: white;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    padding: 24px 36px 24px 36px;
`;

const Wrapper = styled(Flex)`
    max-width: 850px;
`;

const Title = styled(Text)`
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;
