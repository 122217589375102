import { useAuthDetailStore } from "../../stores/useAuthStore";

export const ROLES = ["ADMIN", "HR", "MANAGER", "EMPLOYEE"];
export const ROLES_CODE = {
    employee: "EMPLOYEE",
    admin: "ADMIN",
    manager: "MANAGER",
    hr: "HR",
};
export const roles_access = ({ usersPrivileges }) => {
    const detail = useAuthDetailStore?.getState()?.detail;

    const array_roles = detail?.roles?.map((el) => el?.code);

    const isAuthorized = usersPrivileges?.some((el) =>
        array_roles?.includes(el)
    );

    return isAuthorized;
};
