import React from "react";
import { Button, CallIcon, Flex, Text } from "@fluentui/react-northstar";
import { LinkText } from "./style";

const CallMobile = ({ content, width = "300px", noBorder = false }) => {
    return (
        <Flex
            vAlign="center"
            gap="gap.small"
            space="between"
            styles={{ width: width }}
        >
            {content !== "" ? (
                <LinkText content={content?.replaceAll(" ", ".")} />
            ) : (
                <Text content={"-"} />
            )}

            {content !== "" && (
                <Button
                    as="a"
                    href={`tel:${content}`}
                    iconOnly
                    icon={<CallIcon outline />}
                    flat
                    text={noBorder}
                />
            )}
        </Flex>
    );
};

export default CallMobile;
