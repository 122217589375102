import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import ImgForEmptyData from "./ImgForEmptyData.svg";
import styled from "styled-components";

export default function EmptyData() {
    return (
        <EmptyFlex column fill hAlign="center">
            <Image src={ImgForEmptyData} alt="empty data" />
            <TextStyle
                content={"Aucun processus de Pré-onboarding à démarrer"}
            />
        </EmptyFlex>
    );
}

const TextStyle = styled(Text)`
    color: #000;
    text-align: center;

    /* Title/Title2 */
    font-family: "Segoe UI";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

const EmptyFlex = styled(Flex)`
    padding-block: 26px 70px;
`;
