import { Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import EmployeeTable from "./EmployeeTable";

export default function DynamicTabContent({ users, TabView, AgencyId }) {
    return (
        <MainContentOnboarding column>
            <Header fill space="between">
                <LeftText content="Liste des collaborateurs" />
                <Flex vAlign="center" gap="gap.smaller">
                    <Text content="Collaborateur en " />
                    <RightText content="période d'essai" />
                    <TaskCount>{users?.length}</TaskCount>
                </Flex>
            </Header>
            <Flex fill>
                <EmployeeTable
                    users={users}
                    TabView={TabView}
                    AgencyId={AgencyId}
                />
            </Flex>
        </MainContentOnboarding>
    );
}
const TaskCount = styled(Text)`
    background-color: #0067b8;
    color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MainContentOnboarding = styled(Flex)`
    border-top: 4px solid #9299f7;
    background: #fff;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
    margin-block: 24px;
`;

const Header = styled(Flex)`
    padding: 32px 24px;
`;

const LeftText = styled(Text)`
    color: #242424;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const RightText = styled(Text)`
    color: #0067b8;
    font-family: "Segoe UI";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
