import React, { createRef, useCallback, useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../../../common/styles";
import {
    AddIcon,
    Box,
    Button,
    CloseIcon,
    Dialog,
    Flex,
    Loader,
    MeetingTimeIcon,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import SliderTabs from "../../../../components/shared/SliderTabs";
import FullCalendar from "@fullcalendar/react";
import frLocale from "@fullcalendar/core/locales/fr";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import {
    event_content_function,
    format_events_HR,
    refetchTeamBadge,
} from "../../utils";
import {
    useQuery,
    useMutation,
    QueryClient,
    useQueryClient,
} from "react-query";
import {
    LeaveAllSociety,
    _api_approve_or_refuse_leave,
    _api_cancel_leave,
    allSocietyLeave,
} from "../../api";
import RhLeaveList from "./RhLeaveList";
import AbsenceRequest from "./AbsenceRequest";
import moment from "moment";

import Toast from "../../../../components/Alerts";
import SoldeAjustement from "./ajustementSolde/SoldeAjustement";
import Timeline from "../../../../components/Timeline";
import DialogLeaveDetail from "../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import { useSearchParams } from "react-router-dom";
import { DEVICE } from "../../../../constants/responsive";
import { BoxStyle } from "./CollabCalendar";
import ManagerSoldeDetail from "./ManagerSoldeDetail";
import { AddSubtractCircle20Regular } from "@fluentui/react-icons";

export const TYPE_BUTTONS = {
    refuse: "REFUSE",
    approve: "APPROVE",
};

const INIT_CONFIG_DATA = {
    visible: false,
    data: null,
};

const RhAbsence = ({ isRH, isManager, subHeader, holidayList }) => {
    const calendarRef = createRef();
    const [searchParams, setSearchParams] = useSearchParams();
    const [CleanParams, setCleanParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState(null);
    const [open, setOpen] = useState(false);
    const [approuveLeaveId, setApprouveLeaveId] = useState(null);
    const [configDetail, setConfigDetail] = useState(INIT_CONFIG_DATA);
    const [showDialogAddLeave, setShowDialogAddLeave] = useState({
        visible: false,
        start_date: null,
        end_date: null,
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogAjustement, setOpenDialogAjustement] = useState(false);
    const [currentMonth, setCurrentMonth] = useState({ month: "", year: "" });
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const param_page = searchParams.get("page");
    useEffect(() => {
        if (param_page === "ajust_solde") {
            return setActiveTab(3);
        }
    }, [param_page]);

    const {
        data: allSocietyDataLeave,
        isLoading: isLoadingDataSociety,
        isFetching: isFetchingDataSociety,
        refetch: refetchAllSocietyLeave,
    } = useQuery(
        ["allSocietyLeave", currentMonth.month, currentMonth.year],
        () => LeaveAllSociety(currentMonth.month, currentMonth.year),
        {
            // refetchOnWindowFocus: true,
            // staleTime: 25000,
        }
    );

    const showDialog = (e, events) => {
        const { id } = e.event;
        const current_data_event = events?.find(
            (el) => el?.id === parseInt(id)
        );

        setConfigDetail({ visible: true, data: current_data_event });
        setOpen(true);
    };

    const selectableDate = (arg) => {
        const get_start_date = arg.startStr;
        const get_end_date = arg.endStr;

        const format_end_date = moment(get_end_date)
            .subtract(1, "days")
            .format("YYYY-MM-DD");

        return setShowDialogAddLeave((state) => {
            return {
                ...state,
                visible: true,
                start_date: get_start_date,
                end_date: format_end_date,
            };
        });
    };

    const renderDayCellContent = (info) => {
        const currentCalendarDate = moment(info?.date).format("YYYY-MM-DD");
        const isHoliday = holidayList?.find(
            (holiday) => holiday?.date === currentCalendarDate
        );

        if (isHoliday) {
            return <div className="holiday">Jour férié</div>;
        }
    };

    if (isLoadingDataSociety || isFetchingDataSociety) {
        <Flex
            column
            styles={{ width: "100vw" }}
            vAlign="center"
            hAlign="center"
        >
            <Loader hAlign="center" label="Chargement des données..." />
        </Flex>;
    }

    const format_events_full_calendar_RH = () => {
        return format_events_HR(allSocietyDataLeave?.leaves || []);
    };

    let events_calendar = format_events_full_calendar_RH();

    const DisplayDataIntoCalendar = (data) => {
        setCurrentMonth({
            month: data.view.currentStart.getMonth() + 1,
            year: data.view.currentStart.getFullYear(),
        });
    };

    const queryClient = useQueryClient();

    return (
        <ContainerFluid fill>
            <Container fill column>
                <BoxStyle gap="gap.medium" column>
                    <Toast
                        visible={toastConfig?.visible}
                        content={toastConfig?.content}
                        level={toastConfig?.level}
                        onStatusChange={() => {
                            return setToastConfig({
                                visible: false,
                                content: "",
                                level: "warning",
                            });
                        }}
                    />
                    <DialogLeaveDetail
                        open={configDetail?.visible}
                        leave_details={{
                            requested_by: {
                                email: configDetail?.data?.data?.requested_by,
                            },
                            id: configDetail?.data?.data?.id,
                            approved_by: configDetail?.data?.data?.approved_by,
                            authenticated_user_can_delete:
                                configDetail?.data?.data
                                    ?.authenticated_user_can_delete,
                            authenticated_user_can_validate:
                                configDetail?.data?.data
                                    ?.authenticated_user_can_validate,
                            balance_before_leave:
                                configDetail?.data?.data?.balance_before_leave,
                            balance_after_leave:
                                configDetail?.data?.data?.balance_after_leave,
                            start_date: configDetail?.data?.data?.start_date,
                            end_date: configDetail?.data?.data?.end_date,
                            balance_count_to_remove:
                                configDetail?.data?.data
                                    ?.balance_count_to_remove,

                            comment: configDetail?.data?.data?.comment,
                            documents: configDetail?.data?.data?.documents,
                            type: {
                                color: configDetail?.data?.data?.type?.color,
                                name: configDetail?.data?.data?.type?.name,
                                has_balance:
                                    configDetail?.data?.type?.has_balance,
                            },
                            status: {
                                id: configDetail?.data?.data?.status?.id,
                            },
                            request_can_be_canceled:
                                configDetail?.data?.data
                                    ?.request_can_be_canceled,
                            requested_on:
                                configDetail?.data?.data?.requested_on,
                            waiting_approval_by:
                                configDetail?.data?.data?.waiting_approval_by,
                            last_action: {
                                date: configDetail?.data?.last_action?.date,
                                status: configDetail?.data?.last_action?.status,
                            },
                            authenticated_user_can_remind_external_manager:
                                configDetail?.data
                                    ?.authenticated_user_can_remind_external_manager,
                        }}
                        user_details={{
                            name: configDetail?.data?.data?.user?.name,
                            id: configDetail?.data?.data?.user?.id,
                            avatar: configDetail?.data?.data?.user?.avatar,
                        }}
                        cbOnApproveLeave={(data) => {
                            refetchTeamBadge(queryClient);
                            refetchAllSocietyLeave();
                            return setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "La demande a bien été acceptée  "
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                        }}
                        cbOnCancelLeave={(data) => {
                            refetchTeamBadge(queryClient);
                            refetchAllSocietyLeave();
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "Votre demande a bien été annulée"
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            // refetchTeamCall();
                            return;
                        }}
                        cbOnDeleteLeave={(data) => {
                            refetchTeamBadge(queryClient);
                            refetchAllSocietyLeave();
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "La demande a bien été supprimée  "
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });

                            return;
                        }}
                        cbOnRefuseLeave={(data) => {
                            refetchTeamBadge(queryClient);
                            refetchAllSocietyLeave();
                            setToastConfig({
                                visible: true,
                                content:
                                    data?.success === true
                                        ? "Votre demande a bien été refusée "
                                        : data?.message,
                                level:
                                    data?.success === true
                                        ? "success"
                                        : "danger",
                            });
                            // refetchTeamCall();
                            return;
                        }}
                        cbOnHideDialog={() => {
                            refetchTeamBadge(queryClient);
                            setConfigDetail(INIT_CONFIG_DATA);
                        }}
                    />

                    <FlexStyle gap="gap.large" column>
                        <Flex vAlign="center" space="between">
                            <SliderTabs
                                tabs={[
                                    "Planning",
                                    "Calendrier",
                                    "Liste des demandes",
                                    "Historique des ajustements",
                                    "Soldes de congés",
                                ]}
                                // defaultActiveIndex={0}
                                defaultActiveIndex={
                                    activeTab !== null
                                        ? activeTab
                                        : approuveLeaveId !== null
                                        ? approuveLeaveId
                                        : 0
                                }
                                onChange={(e) => {
                                    // remove the params query
                                    searchParams.delete("page");
                                    setSearchParams(searchParams);
                                    setActiveTab(e);
                                    setCleanParams("");
                                }}
                            />

                            <FlexCTA gap="gap.large">
                                {activeTab === 3 && (
                                    <Button
                                        icon={
                                            <AddSubtractCircle20Regular size="small" />
                                        }
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenDialogAjustement(true);
                                        }}
                                        content="Ajuster un solde "
                                    />
                                )}

                                {activeTab < 3 && (
                                    <AbsenceRequest
                                        showDialogAddLeave={showDialogAddLeave}
                                        setShowDialogAddLeave={
                                            setShowDialogAddLeave
                                        }
                                        // refetchPersonnalCall={() => null}
                                        isForTeams={isRH}
                                        isRH={isRH}
                                        subHeader={subHeader}
                                        activeTab={activeTab}
                                    />
                                )}
                            </FlexCTA>
                        </Flex>
                        {(activeTab === 0 || activeTab === null) && (
                            <Timeline isOnHrPage={true} />
                        )}
                        {/* fullCalendar  */}
                        {activeTab === 1 && (
                            <Flex
                                styles={{
                                    margin: "20px",
                                }}
                                gap="gap.large"
                                column
                            >
                                <WrapperCalendar gap="gap.large">
                                    <FullCalendarStyle
                                        plugins={[
                                            dayGridPlugin,
                                            interactionPlugin,
                                            timeGridPlugin,
                                        ]}
                                        buttonText={{
                                            month: "Mois",
                                            week: "Semaine",
                                            today: "Aujourd'hui",
                                        }}
                                        headerToolbar={{
                                            left: "today,prev,next",
                                            center: "title",
                                            right: "",
                                        }}
                                        locale={frLocale}
                                        // navLinks={true}
                                        editable={false}
                                        aspectRatio={3}
                                        dayMaxEvents
                                        initialView="dayGridMonth"
                                        eventClick={(e) =>
                                            showDialog(e, events_calendar)
                                        }
                                        events={events_calendar}
                                        ref={calendarRef}
                                        firstDay={1}
                                        nowIndicator={true}
                                        selectable
                                        select={selectableDate}
                                        dayCellContent={renderDayCellContent}
                                        eventContent={event_content_function}
                                        datesSet={(arg) => {
                                            DisplayDataIntoCalendar(arg);
                                        }}
                                    />
                                    <WrapperCTA_Mobile>
                                        <AbsenceRequest
                                            showDialogAddLeave={
                                                showDialogAddLeave
                                            }
                                            setShowDialogAddLeave={
                                                setShowDialogAddLeave
                                            }
                                            // refetchPersonnalCall={() => null}
                                            isForTeams={isRH}
                                            isRH={isRH}
                                            subHeader={subHeader}
                                        />
                                    </WrapperCTA_Mobile>
                                </WrapperCalendar>
                            </Flex>
                        )}

                        {activeTab === 2 && (
                            <RhLeaveList
                                allSocietyDataLeave={allSocietyDataLeave}
                                approuveLeaveId={approuveLeaveId}
                                isLoadingDataSociety={
                                    isLoadingDataSociety ||
                                    isFetchingDataSociety
                                }
                                refetchAllSocietyLeave={refetchAllSocietyLeave}
                            />
                        )}
                        {activeTab === 3 && (
                            <Flex column gap="gap.large">
                                <SoldeAjustement
                                    isRH={isRH}
                                    subHeader={3}
                                    openDialog={openDialogAjustement}
                                    setOpenDialog={setOpenDialogAjustement}
                                />
                                <FlexCTA__Mobile gap="gap.large">
                                    {activeTab === 3 && (
                                        <ButtonStyle
                                            icon={
                                                <AddSubtractCircle20Regular size="small" />
                                            }
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpenDialog(true);
                                            }}
                                            content="Ajuster un solde"
                                        />
                                    )}

                                    <AbsenceRequest
                                        showDialogAddLeave={showDialogAddLeave}
                                        setShowDialogAddLeave={
                                            setShowDialogAddLeave
                                        }
                                        // refetchPersonnalCall={() => null}
                                        isForTeams={isRH}
                                        isRH={isRH}
                                        subHeader={subHeader}
                                    />
                                </FlexCTA__Mobile>
                            </Flex>
                        )}

                        {activeTab === 4 && (
                            <ManagerSoldeDetail
                                forRh={true}
                                headerText="l'entreprise"
                                // openDialog={openDialog}
                                // setOpenDialog={setOpenDialog}
                            />
                        )}
                    </FlexStyle>
                </BoxStyle>
            </Container>
        </ContainerFluid>
    );
};

export default RhAbsence;

const WrapperCTA_Mobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 15px;
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: 100%;
    }
    @media ${DEVICE.laptop} {
        min-width: 69px;
    }
`;
const FlexCTA__Mobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
        flex-direction: column-reverse;
        gap: 8px;
        width: 90%;
        margin-bottom: 20px;
        align-self: center;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const FlexCTA = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const WrapperCalendar = styled.div`
    td {
        cursor: pointer;
    }
    .fc-daygrid-day-frame:has(.holiday) {
        background-color: #fbf6d9 !important;
    }

    border-top: 4px solid #9299f7;
    padding: 15px;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const FlexStyle = styled(Flex)`
    margin-top: 24px;
    background-color: white;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    @media ${DEVICE.mobileS} {
        padding: 0px;
        padding-top: 15px;
    }
    @media ${DEVICE.laptop} {
        padding: 15px;
    }
`;

const TitleStyle = styled(Text)`
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

const FullCalendarStyle = styled(FullCalendar)`
    padding-block: 24px;
`;
