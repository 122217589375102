export const NAV_LIST = [
    {
        label: "Onboarding",
        href: "/onboarding",
    },
    {
        label: "Liste des taches",
        href: "/onboarding/detail/:id",
    },
];

export const TABS_ONBOARDING = {
    AGENCY1: "CHAMBERY",
    AGENCY2: "GENEVE",
};

export const DEBOUNCE_DELAY = 700;
