import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const _getAllAgencys = async () => {
    return fetcher(BASE_URL_API + "/onboarding/agencies", {
        method: "GET",
        credentials: "include",
    });
};
export const _getCollaboratorByAgence = async (AgencyId) => {
    return fetcher(BASE_URL_API + `/onboarding/agency/${AgencyId}/users`, {
        method: "GET",
        credentials: "include",
    });
};
export const _startPreonboarding = async (data) => {
    // console.log({ data33: data });
    return fetcher(
        BASE_URL_API + `/onboarding/user/527/step/start_preonboarding`,
        {
            method: "PATCH",
            credentials: "include",
        }
    );
};
export const _relanceWelcomeMail = async (data) => {
    return fetcher(
        BASE_URL_API + `/onboarding/user/527/step/resend_activation_email`,
        {
            method: "PATCH",
            credentials: "include",
        }
    );
};

export const _getUserDetail = async (userId) => {
    return fetcher(BASE_URL_API + `/suivi-onboarding/user/${userId}/details`, {
        method: "GET",
        credentials: "include",
    });
};
