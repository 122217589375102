import React, { useEffect, useState } from "react";
import { ContainerFluid } from "../../../../common/styles";
import {
    Avatar,
    Button,
    ChevronDownIcon,
    Dialog,
    Dropdown,
    ExcelColorIcon,
    Flex,
    InfoIcon,
    Input,
    Loader,
    SearchIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { useQuery } from "react-query";
import { api_get_leaves_list_for_manager, get_team_balance } from "../../api";
import EmptyDemande from "../EmptyDemande";
import moment from "moment";
import { DEVICE } from "../../../../constants/responsive";
import { DEBOUNCE_DELAY } from "../../../../pages/Annuaire/constant";
import { debounce } from "../../../../utils/global";
import { AVATAR_DEFAULT } from "../../../../constants";
import DialogFilterAjustment from "./FilterHistoryAjustment/DialogFilterAjustment";
import ExportAllSoldeRH from "./FilterHistoryAjustment/ExportAllSoldeRH";
import Toast from "../../../../components/Alerts";
import { t } from "i18next";

const ManagerSoldeDetail = ({
    setShowSoldeDetail,
    headerText,
    forRh,
    forManager,
    // openDialog,
    // setOpenDialog,
}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [filtredData, setFilteredData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [configToast, setConfigToast] = useState({
        visible: false,
        level: "success",
        content: "",
    });

    const {
        data: teamBalance,
        isLoading: loadingteamBalance,
        isFetching: fetchingTeamBalance,
    } = useQuery(
        ["manager_solde_data"],
        () => {
            return get_team_balance(forRh);
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setFilteredData(data?.all_users);
                setTotalData(data?.total);
            },
        }
    );

    const { data: users } = useQuery(["userList"], () => {
        return api_get_leaves_list_for_manager();
    });

    useEffect(() => {
        // calculate total
        if (filtredData?.length === 1) {
            let new_total = [];
            for (let i = 0; i < teamBalance?.total.length; i++) {
                const total = teamBalance?.total[i];
                const total_type = teamBalance?.total[i].type;
                const user = filtredData?.at(0)?.balances;
                const user_balance = user?.find((el) => el.type === total_type);
                if (user_balance) {
                    new_total.push({
                        ...total,
                        current_balance: user_balance.current_balance,
                        futur_balance: user_balance.futur_balance,
                    });
                } else {
                    new_total.push({
                        ...total,
                        current_balance: "--",
                        futur_balance: "--",
                    });
                }
            }

            setTotalData(new_total);
        }
    }, [filtredData]);

    if (loadingteamBalance || fetchingTeamBalance)
        return <Loader label="Chargement des données ..." />;

    // const all_users = teamBalance?.all_users;
    const leave_types = teamBalance?.leave_types;
    // const totalBalances = teamBalance?.total;

    const USERS = users?.map((user) => {
        return {
            id: user?.id,
            header: user?.name,
            image: user?.avatar ? user?.avatar : AVATAR_DEFAULT,
            is_department: user?.is_department,
        };
    });

    return (
        <ContainerFluid column fill>
            {/* body of the table  */}
            <Container column styles={{ overflowX: "scroll" }}>
                {/* header text */}
                <Toast
                    onStatusChange={() =>
                        setConfigToast({
                            visible: false,
                            content: "",
                            level: "success",
                        })
                    }
                    level={configToast.level}
                    content={configToast.content}
                    visible={configToast.visible}
                />
                <HeaderAction space="between" vAlign="center" fill>
                    <HeaderFlex fill space="between" vAlign="center">
                        <TextHeader
                            content={`Vue d'ensemble des soldes de ${headerText}`}
                        />
                        {forRh && (
                            <ExportAllSoldeRH
                                setConfigToast={setConfigToast}
                                exportAllSolde={true}
                                openDialog={openDialog}
                                setOpenDialog={setOpenDialog}
                            />
                            // <DialogFilterAjustment />
                        )}
                    </HeaderFlex>
                    {/* <ButtonStyle
                        onClick={() => setShowSoldeDetail(false)}
                        primary
                        text
                        content="Retour"
                    /> */}
                </HeaderAction>
                <>
                    <Flex column>
                        <Flex
                            styles={{
                                height: "400px",
                                overflowY: "auto",
                                paddingBottom: "45px",
                            }}
                        >
                            {/* all users */}
                            <Flex
                                column
                                styles={{
                                    // marginRight: "40px",
                                    marginTop: "0px",
                                }}
                            >
                                <DropdownStyle
                                    fluid
                                    search
                                    items={USERS}
                                    // defaultValue={default_value}
                                    placeholder="Rechercher....."
                                    noResultsMessage="Nous n'avons pas trouvé de correspondance."
                                    a11ySelectedItemsMessage="Appuyez sur la touche Delete ou Backspace pour supprimer"
                                    clearable
                                    onChange={(e, i) => {
                                        const value = i?.value?.id;
                                        if (!value) {
                                            setTotalData(teamBalance?.total);
                                            return setFilteredData(
                                                teamBalance?.all_users
                                            );
                                        }

                                        return setFilteredData((state) => {
                                            return state?.filter(
                                                (el) =>
                                                    "u-" + el?.id ===
                                                    i?.value?.id
                                            );
                                        });
                                    }}
                                />

                                {filtredData?.length === 0
                                    ? null
                                    : filtredData?.map((el) => {
                                          return (
                                              <Column Align="center">
                                                  <FlexAvatarName vAlign="center">
                                                      <Avatar
                                                          styles={{
                                                              width: "40px",
                                                              height: "40px",
                                                          }}
                                                          name={el?.name}
                                                          image={el?.avatar}
                                                      />
                                                      <NameText
                                                          truncated
                                                          content={el?.name}
                                                      />
                                                  </FlexAvatarName>
                                              </Column>
                                          );
                                      })}
                            </Flex>
                            {/* leave types */}
                            {filtredData?.length === 0 ? (
                                <Flex
                                    hAlign="center"
                                    styles={{
                                        width: "80%",
                                    }}
                                >
                                    <EmptyScreen />
                                </Flex>
                            ) : (
                                <Flex
                                    styles={{
                                        gap: "40px",
                                    }}
                                >
                                    {leave_types?.map((el) => {
                                        const smallWidth =
                                            el?.type === "cp" ||
                                            el?.type === "cumul" ||
                                            el?.type === "rtt";

                                        return (
                                            <Flex
                                                column
                                                styles={{
                                                    border: `1px solid ${el?.color}`,

                                                    height: "fit-content",
                                                    minHeight: "400px",
                                                    width: !smallWidth
                                                        ? "136px"
                                                        : "202px",
                                                    // width: el?.type !== "cp"  ? "156px" : "202px",
                                                    borderRadius:
                                                        "9px 9px 0px 0px",
                                                    paddingBottom: "25px",
                                                }}
                                            >
                                                <TableHead
                                                    background={el?.color}
                                                    border={el?.color}
                                                    name={el?.name}
                                                    type={el?.type}
                                                    smallWidth={smallWidth}
                                                />
                                                {!smallWidth ? (
                                                    <Flex
                                                        hAlign="center"
                                                        styles={{
                                                            padding:
                                                                "8px 16px 16px 16px",
                                                        }}
                                                    >
                                                        <RecuperationText content="Actuel" />
                                                    </Flex>
                                                ) : (
                                                    <Flex
                                                        // vAlign="center"
                                                        space="between"
                                                        styles={{
                                                            padding:
                                                                "8px 16px 16px 16px",
                                                        }}
                                                    >
                                                        <Actuellement content="Actuel" />

                                                        <Tooltip
                                                            content={`Fin de la période : ${moment(
                                                                el?.end_period
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )}`}
                                                            subtle={false}
                                                            trigger={
                                                                <DateStyle
                                                                    content={
                                                                        "Fin de période"
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Flex>
                                                )}

                                                <Flex
                                                    column
                                                    style={{
                                                        gap: "14px",
                                                    }}
                                                >
                                                    {filtredData?.map(
                                                        (user) => {
                                                            const balanceInfo =
                                                                user?.balances?.find(
                                                                    (balance) =>
                                                                        balance?.type ===
                                                                        el?.type
                                                                );
                                                            return (
                                                                <Flex
                                                                    gap="gap.medium"
                                                                    fill
                                                                    space="between"
                                                                    style={{
                                                                        height: "40px",
                                                                        paddingInline:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    {/* current balance  */}
                                                                    <Flex
                                                                        vAlign="center"
                                                                        hAlign="center"
                                                                        fill
                                                                    >
                                                                        <CurrentBalanceText
                                                                            content={
                                                                                balanceInfo
                                                                                    ? balanceInfo.current_balance.toFixed(
                                                                                          2
                                                                                      )
                                                                                    : "--"
                                                                            }
                                                                        />
                                                                    </Flex>
                                                                    {balanceInfo?.futur_balance &&
                                                                    smallWidth ? (
                                                                        <FutureBalanceFlex
                                                                            vAlign="center"
                                                                            hAlign="center"
                                                                            fill
                                                                        >
                                                                            <FutureBalanceText
                                                                                content={
                                                                                    balanceInfo?.futur_balance
                                                                                }
                                                                            />
                                                                        </FutureBalanceFlex>
                                                                    ) : null}
                                                                </Flex>
                                                            );
                                                        }
                                                    )}
                                                </Flex>
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            )}
                        </Flex>
                        {/* total équipe  */}
                        {filtredData?.length === 0 ? null : (
                            <Flex
                                styles={{
                                    gap: "38px",
                                    border: "1px solid #edebeb",
                                    width: "fit-content",
                                    padding: "10px 10px",
                                    background: "white",
                                    boxShadow:
                                        "5px 5px 10px rgba(0, 0, 0, 0.07)",
                                }}
                            >
                                <Flex
                                    vAlign="center"
                                    styles={{
                                        width: "192px",
                                    }}
                                >
                                    <TotalText content="Total équipe" />
                                </Flex>
                                {/* total flex  */}
                                <Flex
                                    styles={{
                                        gap: "40px",
                                    }}
                                >
                                    {totalData?.map((balance) => {
                                        const smallWidth =
                                            balance?.type === "cp" ||
                                            balance?.type === "cumul" ||
                                            balance?.type === "rtt";
                                        return (
                                            <Flex
                                                gap="gap.medium"
                                                vAlign="center"
                                                hAlign="center"
                                                styles={{
                                                    // width:balance?.type === "cp"? "202px": "156px",
                                                    width: !smallWidth
                                                        ? "136px"
                                                        : "202px",
                                                    height: "40px",

                                                    paddingInline: "16px",
                                                }}
                                                key={balance?.type}
                                            >
                                                <Flex
                                                    vAlign="center"
                                                    hAlign="center"
                                                    fill
                                                >
                                                    <CurrentBalanceText
                                                        content={`${balance?.current_balance} j`}
                                                    />
                                                </Flex>
                                                {balance?.futur_balance &&
                                                smallWidth ? (
                                                    <FutureBalanceFlex
                                                        vAlign="center"
                                                        hAlign="center"
                                                        fill
                                                    >
                                                        <TotalFutureBalanceText
                                                            content={`${balance?.futur_balance} j`}
                                                        />
                                                    </FutureBalanceFlex>
                                                ) : null}
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                </>
            </Container>
        </ContainerFluid>
    );
};

export default ManagerSoldeDetail;

const EmptyScreen = () => {
    return (
        <Flex column gap="gap.large" hAlign="center">
            <EmptyDemande forLeave={true} forTeamSolde={true} />
            <EmptyUserText content="Aucune personne dans votre équipe pour le moment" />
        </Flex>
    );
};

const DropdownStyle = styled(Dropdown)`
    margin-bottom: 47px;
    margin-top: 12px;
    margin-right: 12px;
    .ui-input__input {
        width: 100%;
    }
`;

const FlexAvatarName = styled(Flex)`
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid white;
    padding-inline: 10px;
    gap: 12px;
`;

const TableHead = ({ background, name, border, type, smallWidth }) => {
    return (
        <Header
            hAlign="center"
            styles={{
                // borderRadius: "4px 4px 0px 0px",
                position: "sticky",
                borderRadius: "9px 9px 0px 0px",
                top: 0,
                width: !smallWidth ? "136px" : "202px",
            }}
        >
            {/* header */}
            <HeaderText
                styles={{
                    background: background,
                    // border: `1px solid ${border}`,
                    borderRadius: "4px 4px 0px 0px",
                }}
                content={
                    <Flex hAlign="center" vAlign="center" gap="gap.small">
                        <Text
                            styles={{
                                color: type === "cumul" ? "white" : "black",
                            }}
                            content={name}
                        />
                        {type === "cumul" && (
                            <Tooltip
                                pointing="above"
                                subtle={false}
                                content={
                                    <Text
                                        content={
                                            "Cumul = CP N-1 + CP N + Récupération + RTT"
                                        }
                                    />
                                }
                                trigger={
                                    <InfoIcon
                                        outline
                                        styles={{ color: "white" }}
                                    />
                                }
                            />
                        )}
                    </Flex>
                }
            />
        </Header>
    );
};

const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: fit-content;
    }
    @media ${DEVICE.laptop} {
        min-width: 96px;
    }
`;

const HeaderAction = styled(Flex)`
    margin-bottom: 30px;
    padding-top: 15px;
    width: 100%;
`;

const Header = styled(Flex)`
    /* width: 199px; */
    border-radius: 8px 8px 0px 0px;
`;

const Container = styled(Flex)`
    max-width: 1440px;
    /* margin: 24px auto; */
    width: 100%;
    background: white;
    @media ${DEVICE.mobileS} {
        padding: 0px 20px 20px 20px;
    }
    @media ${DEVICE.laptop} {
        padding: 0px 40px 20px 40px;
    }
`;

const HeaderText = styled(Text)`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    /* height: 40px; */
    padding-block: 10px;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
`;

const HeaderFlex = styled(Flex)`
    gap: 80px;
`;

const TextHeader = styled(Text)`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
const DateTextStyle = styled(Text)`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #5b5fc7;
`;

const BoxStyle = styled(Flex)`
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #e8ebfa;
`;

const Column = styled(Flex)`
    width: 202px;
    padding-bottom: 12px;
    /* margin-top: 88px; */
    /* height: 200px; */
    margin-right: 40px;
`;

const RecuperationText = styled(Text)`
    color: #616161;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;
const Actuellement = styled(Text)`
    color: #616161;
    margin-left: 15px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const DateStyle = styled(Text)`
    color: #5b5fc7;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const NameText = styled(Text)`
    color: #424242;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const CurrentBalanceText = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
const FutureBalanceText = styled(Text)`
    color: #5b5fc7;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const TotalFutureBalanceText = styled(Text)`
    color: black;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const FutureBalanceFlex = styled(Flex)`
    width: 82px;
    border-radius: 4px;
    background: #e8ebfa;
    flex-shrink: 0;
`;

const TotalText = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const EmptyUserText = styled(Text)`
    color: #616161;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
