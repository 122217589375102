import React from "react";
import styled from "styled-components";
import { Button, Flex } from "@fluentui/react-northstar";

import { Container, ContainerFluid } from "../../../common/styles";
import { Tab, TabList } from "@fluentui/react-components";
import { DEVICE } from "../../../constants/responsive";

const SubHeader = ({ menu = [], setTabView, fromOnboarding }) => {
    return (
        <ContainerFluidStyle fromOnboarding={fromOnboarding}>
            <Container>
                <Wrapper fill vAlign="center" gap="gap.small">
                    <TabList
                        appearance="transparent"
                        defaultSelectedValue={menu.at(0).value}
                    >
                        {menu?.map((el, index) => {
                            return (
                                <Flex vAlign="center">
                                    <Tab
                                        key={`subheader_${index}`}
                                        content={el?.content}
                                        icon={el?.icon}
                                        value={el.value}
                                        onClick={() => setTabView(el?.value)}
                                    />
                                    {el?.action_count ? (
                                        <p>{el?.action_count}</p>
                                    ) : null}
                                </Flex>
                            );
                        })}
                    </TabList>
                </Wrapper>
            </Container>
        </ContainerFluidStyle>
    );
};

export default SubHeader;

const ContainerFluidStyle = styled(ContainerFluid)`
    box-shadow: 0px 6.400000095367432px 14.399999618530273px 0px #00000012,
        0px 1.2000000476837158px 3.5999999046325684px 0px #00000008;
    background-color: ${({ fromOnboarding }) =>
        fromOnboarding ? "#FFF" : "#F5F5F5"};
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
`;

const ButtonStyle = styled(Button)`
    background-color: ${({ active }) => (active ? "#EBEbeb" : "transparent")};
    color: ${({ active }) => (active ? "var(--color-primary)" : "inherit")};
`;

const Wrapper = styled(Flex)`
    height: 48px;
`;
