import { Button, Flex } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import { FolderSwap20Regular } from "@fluentui/react-icons";

export default function SuiviAction() {
    const stopPreonboarding = () => {
        alert("stop preonboarding");
    };

    return (
        <FlexStyled fill hAlign="end">
            <Button
                onClick={stopPreonboarding}
                iconPosition="after"
                primary
                icon={<FolderSwap20Regular />}
                content="Interrompre le suivi"
            />
        </FlexStyled>
    );
}

const FlexStyled = styled(Flex)`
    border-bottom: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: 0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    padding: 24px;
    margin-block: 32px;
`;
