import { Button } from "@fluentui/react-northstar";
import React from "react";
import {
    BuildingBank16Filled,
    CalendarEdit20Regular,
    Edit20Regular,
    FolderLink20Regular,
    Handshake20Regular,
    PeopleTeam16Filled,
} from "@fluentui/react-icons";
export default function FooterActions({
    status_fake_badge,
    updateInformation,
    startPreOnboarding,
    interruptPreOnboarding,
    changeStartDate,
    TabView,
}) {
    const _renderFooterDependingOnTabView = (TabView) => {
        console.log({ TabView99: TabView });
        switch (TabView) {
            case 0:
            case 1:
                return (
                    <>
                        <Button
                            onClick={updateInformation}
                            iconPosition="after"
                            secondary
                            content={"Modifier les informations"}
                            icon={<Edit20Regular />}
                        />
                        <Button
                            loading={false}
                            onClick={() => startPreOnboarding()}
                            iconPosition="after"
                            primary
                            content={"Lancer le pré-onboarding"}
                            icon={<Handshake20Regular />}
                        />
                    </>
                );
            case 2:
            case 3:
                return (
                    <>
                        <Button
                            onClick={changeStartDate}
                            iconPosition="after"
                            secondary
                            content={"Renouveler la période d'essai"}
                            icon={<CalendarEdit20Regular />}
                        />
                        <Button
                            loading={false}
                            onClick={interruptPreOnboarding}
                            iconPosition="after"
                            primary
                            content={"Interrompre l'onboarding"}
                            icon={<FolderLink20Regular />}
                        />
                    </>
                );

            default:
                return (
                    <>
                        <Button
                            onClick={updateInformation}
                            iconPosition="after"
                            secondary
                            content={"Modifier les informations"}
                            icon={<Edit20Regular />}
                        />
                        <Button
                            loading={false}
                            onClick={() => startPreOnboarding()}
                            iconPosition="after"
                            primary
                            content={"Lancer le pré-onboarding"}
                            icon={<Handshake20Regular />}
                        />
                    </>
                );
        }
    };

    return _renderFooterDependingOnTabView(TabView);
}
