import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_KEY } from "../../constants";
import {
    _api_delete_task,
    _api_get_detail_one_task,
    _api_validate_document,
    _update_status_task,
} from "../../../../api/tasks";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import ModalDetailTask from "../../ModalDetailTask";
import { Container, ContainerFluid } from "../../../../common/styles";
import {
    IllustrationSubText,
    IllustrationText,
    SubHeader,
    SubHeaderText,
    Title,
} from "../styles";
import { ChevronLeft } from "@mui/icons-material";
import LoaderPage from "../../../../components/loaders/LoaderPage";
import { TASK_STATUS } from "../../../../constants/tasks";
import PdfViewerComponent from "../../../../components/PdfViewerComponent";
import Illustration from "../../../../components/shared/Illustration";

import styled from "styled-components";
import { DEVICE } from "../../../../constants/responsive";
import { useAuthDetailStore } from "../../../../stores/useAuthStore";
import HeaderPreonboarding, {
    MobileHeaderPreonboarding,
} from "../../../../features/pre_onboarding/components/HeaderPreonboarding";
import { _preonboarding } from "../../../../features/pre_onboarding/api";
import { useMediaQuery } from "react-responsive";

const GeneralDetailTaskContent = ({ is_in_preonboarding }) => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const detail = useAuthDetailStore((state) => state.detail);

    const isHrPage = state?.isHrPage ?? false;

    const { id_task, id_user } = useParams();
    const [savedFiles, setSavedFiles] = useState([]);
    const [document, setDocument] = useState(null);

    // pspdfkit data
    const [pspdfkitDocumentId, setPspdfkitDocumentId] = useState(null);
    const [pspdfkitJwt, setPspdfkitJwt] = useState(null);
    const [pspdfkitEnableAnnotation, setPspdfkitEnableAnnotation] =
        useState(null);
    const isMobile = useMediaQuery({
        query: "(max-width: 500px)",
    });
    const { data, isLoading, isFetching } = useQuery(
        [QUERY_KEY + "::get_detail_one_task"],
        () => {
            return _api_get_detail_one_task({
                id_task,
                id_user,
            });
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    // /me data for displaying arrival date in the header of the page
    const {
        isLoading: isLoadingMeData,
        isFetching: isFetchingMeData,
        data: meData,
    } = useQuery(["preonboarding_information"], () => _preonboarding());

    // mutations
    // * complete task
    const {
        isLoading: isMutatingCompleteTask,
        mutateAsync: mutateCompleteTask,
    } = useMutation((data) => _api_validate_document(data));

    // * update status task
    const {
        isLoading: isMutatingUpdateStatus,
        mutateAsync: mutateUpdateStatus,
    } = useMutation((data) => _update_status_task(data));

    const { isLoading: is_deleting_task, mutate } = useMutation((data) =>
        _api_delete_task(data)
    );

    useEffect(() => {
        if (data?.task?.documents?.length) {
            setDocument(data?.task?.documents?.at(0)?.attached_doc);
            setPspdfkitDocumentId(
                data?.task?.documents?.at(0)?.pspdfkit_document_id
            );
            setPspdfkitJwt(data?.task?.documents?.at(0)?.pspdfkit_jwt);
            setPspdfkitEnableAnnotation(
                data?.task?.documents?.at(0)?.pspdfkit_enable_annotation
            );
            return;
        }
        setDocument(data?.task?.documents_template?.at(0)?.attached_doc);
        setPspdfkitDocumentId(
            data?.task?.documents_template?.at(0)?.pspdfkit_document_id
        );
        setPspdfkitJwt(data?.task?.documents_template?.at(0)?.pspdfkit_jwt);
        setPspdfkitEnableAnnotation(
            data?.task?.documents_template?.at(0)?.pspdfkit_enable_annotation
        );
        return;
    }, [
        data?.task?.documents_template?.at(0)?.attached_doc,
        data?.task?.documents_template?.at(0)?.pspdfkit_document_id,
        data?.task?.documents_template?.at(0)?.pspdfkit_jwt,
        data?.task?.documents_template?.at(0)?.pspdfkit_enable_annotation,
    ]);

    if (isLoading || isFetching) {
        return <LoaderPage />;
    }

    const goBack = ({ message = null }) => {
        // console.log(event);
        const data = {};

        if (message) data.message = message;
        // if is in preonboarding
        if (is_in_preonboarding) {
            // event.preventDefault();
            return navigate("/welcome-preonboarding", { state: data });
        }

        // return navigate("/welcome-preonboarding");
        return navigate(state?.referrer ?? "/dashboard/tasks", { state: data });
    };

    /** show the value of the button According to CASE  */
    const _renderEmployeeValueButton = (task) => {
        // si la tache est "a faire" + document A SIGNE
        if (task.status === TASK_STATUS.TODO && task.document_action === 4) {
            return "Valider la signature du document";
        }
        // si la tache est "a faire" + document A COMPLETE
        if (task.status === TASK_STATUS.TODO && task.document_action === 3) {
            return "Envoyer le document complété";
        }
        // si la tache est "a faire" + document CONSULTE
        if (task.status === TASK_STATUS.TODO && task.document_action === 2) {
            return "Valider la lecture du document";
        }
        // si la tache est "a faire" + document A ENVOYER
        if (task.status === TASK_STATUS.TODO && task.document_action === 1) {
            return "Envoyer le document";
        }
        // si la tache est "a faire" + document AUCUNE ACTION
        if (task.status === TASK_STATUS.TODO && task.document_action === 0) {
            return "Terminer la tâche";
        }
        return false;
    };

    /** execute mutates According to CASES  */
    const executeTaskDependStatus = async (task, PREMIUM_PLAN_FILLABAL) => {
        // envoyer les documents vers backend relier avec une tâche.
        if (
            !PREMIUM_PLAN_FILLABAL &&
            task.document_required &&
            task.status !== TASK_STATUS.TO_APPROVE
        ) {
            if (!savedFiles.length) {
                alert("Veuillez charger un document pour pouvoir envoyer");
                return;
            }
            const fetched_complete = await mutateCompleteTask({
                task_id: task.id,
                new_doc_ids: savedFiles.map((el) => el.id),
                assigned_to: task.assigned_to_user.id,
            });
        }

        if (task.status === TASK_STATUS.TODO) {
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: task.task_to_validate
                    ? TASK_STATUS.TO_APPROVE
                    : TASK_STATUS.DONE,
                body: {
                    pspdfkit_document_id: PREMIUM_PLAN_FILLABAL
                        ? pspdfkitDocumentId
                        : null,
                    upload_to_pspdfkit: true,
                },
            });
            if (response?.success) {
                return goBack({ message: "Votre action est bien terminée" });
            }
        }

        if (task.status === TASK_STATUS.TO_APPROVE) {
            // si c'est le collaborateur qui est connecte
            // alors change status to TODO directement.
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: TASK_STATUS.DONE,
            });

            if (response?.success) {
                return goBack({ message: "Votre action est bien terminée" });
            }
        }
        console.log("NOTHING");
    };

    // cette fonction est utilisé juste pour Afficher le document Uploadé dans la dropzone
    const onUploadPspdfkitSuccess = (file) => {
        // update les données juste pour le preview PSPDFKIT
        setPspdfkitDocumentId(file.pspdfkit_document_id);
        setPspdfkitJwt(file.pspdfkit_jwt);
        setPspdfkitEnableAnnotation(file.pspdfkit_enable_annotation);
        setDocument(file.uploaded_doc);
        return;
    };
    // cette fonction est utilisé pour RESET les detail PSPDFKIT
    const onResetPspdfkit = () => {
        setPspdfkitDocumentId(null);
        setPspdfkitJwt(null);
        setPspdfkitEnableAnnotation(null);
        setDocument(null);
        return;
    };

    const task = data?.task;

    const hideButtons =
        task?.status === TASK_STATUS.DONE ||
        task?.status === TASK_STATUS.TO_APPROVE ||
        isHrPage;

    const illustration_message =
        (task?.document_action === 0 || isHrPage) && !document
            ? "Aucun document à visualiser"
            : "";

    const has_pdf_premium_plan = detail?.company?.has_pdf_premium_plan; // forcer a True;

    const PREMIUM_PLAN_FILLABAL =
        has_pdf_premium_plan &&
        task.status === TASK_STATUS.TODO &&
        [3, 4].includes(task?.document_action);

    const show_preview_document = document && document !== "undefined";

    return (
        <ContainerFluid column>
            <Container column>
                {/* header */}
                {is_in_preonboarding &&
                    (isMobile ? (
                        <MobileHeaderPreonboarding
                            arrival_date={meData?.me?.arrival_date}
                        />
                    ) : (
                        <HeaderPreonboarding
                            arrival_date={meData?.me?.arrival_date}
                        />
                    ))}
                <SubHeader vAlign="center" gap="gap.small">
                    <Button
                        iconOnly={true}
                        icon={<ChevronLeft />}
                        flat
                        onClick={goBack}
                    />
                    <SubHeaderText content={"Détails d'une tâche "} />
                </SubHeader>
                {/* BODY */}
                <MainContent fill>
                    {/* left */}
                    <Flex
                        styles={{
                            padding: "24px",
                            maxWidth: isMobile ? "100%" : "430px",
                            height: "fit-content",
                            backgroundColor: "#FFF",
                            boxShadow:
                                "0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13)",
                        }}
                        column
                        fill
                        gap="gap.large"
                    >
                        <Flex column>
                            <Title>{data?.task?.name}</Title>
                            <ModalDetailTask
                                data={{ ...task }}
                                savedFiles={savedFiles}
                                setSavedFiles={setSavedFiles}
                                hideCreationAndValidation={false}
                                isPreviewDocument={true}
                                documentToPreview={document}
                                isPremiumPspdfkit={PREMIUM_PLAN_FILLABAL}
                                isHrPage={isHrPage}
                                onUploadPspdfkitSuccess={
                                    onUploadPspdfkitSuccess
                                }
                                onResetPspdfkit={onResetPspdfkit}
                                onClickToPreview={(documents_template) => {
                                    setPspdfkitDocumentId(
                                        documents_template?.pspdfkit_document_id
                                    );
                                    setPspdfkitJwt(
                                        documents_template?.pspdfkit_jwt
                                    );
                                    setPspdfkitEnableAnnotation(
                                        documents_template?.pspdfkit_enable_annotation
                                    );
                                    return setDocument(
                                        documents_template?.attached_doc
                                    );
                                }}
                            />
                        </Flex>
                        {/* footer => button actions */}
                        <Flex hAlign="center">
                            {!hideButtons && (
                                <Button
                                    fluid={isMobile ? true : false}
                                    content={_renderEmployeeValueButton(task)}
                                    onClick={() => {
                                        return executeTaskDependStatus(
                                            task,
                                            PREMIUM_PLAN_FILLABAL
                                        );
                                    }}
                                    loading={
                                        isMutatingCompleteTask ||
                                        isMutatingUpdateStatus
                                    }
                                    disabled={
                                        PREMIUM_PLAN_FILLABAL ||
                                        // or document action n'est pas to sign
                                        data?.document_action !== 4
                                            ? false
                                            : savedFiles?.length === 0 &&
                                              task.document_required &&
                                              task.status !==
                                                  TASK_STATUS.TO_APPROVE
                                    }
                                    primary
                                    flat
                                />
                            )}
                            {isHrPage && (
                                <Button
                                    content={"Supprimer la tâche"}
                                    fluid
                                    flat
                                    loading={is_deleting_task}
                                    onClick={() => {
                                        mutate(
                                            {
                                                id_user:
                                                    task?.assigned_to_user?.id,
                                                task_id: task?.id,
                                            },
                                            {
                                                onSuccess: (data) => {
                                                    if (data?.success) {
                                                        return goBack({
                                                            message:
                                                                "Votre action est bien terminée",
                                                        });
                                                    }
                                                },
                                            }
                                        );
                                        return;
                                    }}
                                    styles={{
                                        color: "var(--color-danger)",
                                    }}
                                />
                            )}
                        </Flex>
                    </Flex>
                    {/* right */}
                    <Flex
                        fill
                        styles={{
                            backgroundColor: "transparent",
                            height: "80vh",
                            width: "100%",
                        }}
                        className="PDF-viewer"
                    >
                        {show_preview_document && (
                            <PdfViewerComponent
                                documentId={pspdfkitDocumentId}
                                jwt={pspdfkitJwt}
                                enableAnnotation={pspdfkitEnableAnnotation}
                            />
                        )}

                        {/* {data?.task?.documents?.length ? (
                            <PdfViewerComponent
                                documentId={
                                    data?.task?.documents[0]
                                        ?.pspdfkit_document_id
                                }
                                jwt={data?.task?.documents[0]?.pspdfkit_jwt}
                                enableAnnotation={
                                    data?.task?.documents[0]
                                        ?.pspdfkit_enable_annotation
                                }
                            />
                        ) : null} */}

                        {task?.document_action === 0 && (
                            <Flex
                                column
                                hAlign="center"
                                vAlign="center"
                                gap="gap.small"
                                fill
                            >
                                <Illustration
                                    filename={"tasks/tasks_document.svg"}
                                    width="250px"
                                    height="210px"
                                />
                                <IllustrationText
                                    content={illustration_message}
                                    weight="bold"
                                />
                            </Flex>
                        )}
                        {task?.document_action === 1 &&
                            savedFiles.length === 0 &&
                            task.status !== TASK_STATUS.DONE &&
                            !isHrPage &&
                            !document && (
                                <Flex
                                    column
                                    hAlign="center"
                                    vAlign="center"
                                    gap="gap.small"
                                    fill
                                >
                                    <Illustration
                                        filename={"tasks/tasks_document.svg"}
                                        width="250px"
                                        height="210px"
                                    />
                                    <IllustrationText
                                        content={
                                            "Vous n'avez pas importé de document"
                                        }
                                        weight="bold"
                                    />
                                    <IllustrationSubText
                                        content={
                                            "Pour en importer un veuillez utiliser l’élément en violet sur votre gauche"
                                        }
                                    />
                                </Flex>
                            )}
                    </Flex>
                </MainContent>
            </Container>
        </ContainerFluid>
    );
};

export default GeneralDetailTaskContent;

const MainContent = styled(Flex)`
    background-color: #f5f5f5;

    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 16px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 16px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 16px;
    }
    @media ${DEVICE.laptopL} {
        flex-direction: row;
        gap: 16px;
    }
`;
