import React from "react";
import {
    ArrowRightIcon,
    CloseIcon,
    Dialog,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import AvatarName from "../../AvatarName";
import { useQuery } from "react-query";
import { api_balances_detail_timeline } from "../../../../../features/leave/api";
import Content from "./Content";
import { StyleGuide20Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { I18N_NS_LEAVE } from "../../../../../features/leave/constants";

const DialogSoldeEmployee = ({
    id_employee,
    user,
    open,
    onCancelDialog,
    is_manager,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation(I18N_NS_LEAVE);

    const { data: employee_data, isLoading } = useQuery(
        ["timeline::getSoldeEmployee", id_employee],
        () =>
            api_balances_detail_timeline({
                id_user: id_employee,
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const navigateToAjustSolde = (e) => {
        e.preventDefault();
        navigate("/leave?page=ajust_solde");
        onCancelDialog();
        return;
    };

    const configConfirmButton =
        is_manager === true
            ? null
            : {
                  content: (
                      <Text
                          styles={{ color: "#0067B8" }}
                          content={t("timeline.buttons.ajust_soldes")}
                      />
                  ),
                  flat: true,
                  icon: <ArrowRightIcon styles={{ color: "#0067B8" }} />,
                  text: true,
                  iconPosition: "after",
                  onClick: navigateToAjustSolde,
              };

    return (
        <Dialog
            open={open}
            header={
                <AvatarName image={user.avatar} name={user.name} size="large" />
            }
            content={
                isLoading ? (
                    <Loader />
                ) : (
                    <Content employee_data={employee_data} />
                )
            }
            confirmButton={configConfirmButton}
            cancelButton={{
                content: t("common.cancel"),
                flat: true,
            }}
            onCancel={onCancelDialog}
            headerAction={{
                icon: <CloseIcon />,
                onClick: onCancelDialog,
            }}
            styles={{
                width: "764px",
            }}
        />
    );
};

export default DialogSoldeEmployee;
