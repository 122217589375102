import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../../../common/styles";
import CustomBreadCrumb from "../../../../components/breadcrumb";
import { NAVLIST_SUIVI } from "./Constant";
import { _getAllAgencys } from "../../api";
import { useQuery } from "react-query";
import { Flex, Loader } from "@fluentui/react-northstar";
import SubHeader from "../../../../components/breadcrumb/SubHeader";
import ContentSuiviPage from "./ContentSuiviPage";
import HeaderUserDetail from "../commun/HeaderUserDetail";
import styled from "styled-components";
import SuiviAction from "./SuiviAction";
export default function Suivi() {
    useEffect(() => {
        document.title = "Suivi des collaborateurs";
    }, []);
    const { isLoading, data, error } = useQuery(["agencys"], () =>
        _getAllAgencys()
    );
    const [TabView, setTabView] = useState(data ? data[0]?.id : []);
    console.log({ TabView });
    const menuItems = data?.map((agency) => ({
        content: agency.name,
        value: agency.id,
    }));
    console.log({ data });

    return (
        <ContainerStyled fill column>
            <CustomBreadCrumb navList={NAVLIST_SUIVI} />
            {isLoading ? (
                <Loader content="Loading ....." />
            ) : (
                <>
                    <SubHeader
                        fromOnboarding={true}
                        menu={data ? menuItems : []}
                        setTabView={setTabView}
                    />

                    <Container column>
                        <HeaderUserDetail />
                        <ContentSuiviPage />
                    </Container>
                    <Container fill>
                        <SuiviAction />
                    </Container>
                </>
            )}
        </ContainerStyled>
    );
}

const ContainerStyled = styled(ContainerFluid)`
    background: #f5f5f5;
`;
