import { AcceptIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";

export default function StatusBadge({ status }) {
    const status2 = [
        {
            id: 1,
            name: "Compte créé",
            status_name: "waiting_preonboarding",
        },
        {
            id: 2,
            name: "Pré-onboarding",
            status_name: "preonboarding",
        },
        {
            id: 3,
            name: "Onboarding",
            status_name: "onboarding",
        },
        {
            id: 4,
            name: "Suivi",
        },
    ];

    console.log({ status });
    const stepper = status2.find((item) => item?.status_name == status);
    console.log({ stepper });

    return (
        <Flex gap="gap.large">
            {status2?.map((statusItem) => {
                const highlight = statusItem?.id == stepper?.id;
                console.log({ status1: statusItem?.id });
                console.log({ status2: stepper?.id });
                return (
                    <BadgeStatus key={statusItem?.id} column>
                        <StatusNumber
                            highlight={highlight}
                            content={
                                stepper?.id > statusItem?.id ? (
                                    <AcceptIcon />
                                ) : (
                                    statusItem?.id
                                )
                            }
                        />
                        <StatusName
                            highlight={highlight}
                            content={statusItem?.name}
                        />
                    </BadgeStatus>
                );
            })}
        </Flex>
    );
}

const StatusNumber = styled(Text)`
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    border-radius: 40px;
    background-color: ${({ highlight }) => (highlight ? "#5b5fc7" : "#C7C7C7")};
    width: 28px;
    height: 28px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
`;

const BadgeStatus = styled(Flex)`
    width: 120px;
    height: 54px;
    justify-content: center;
    align-items: center;
    gap: 4px;
`;

const StatusName = styled(Text)`
    color: ${({ highlight }) => (highlight ? "#5b5fc7" : "#C7C7C7")};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
