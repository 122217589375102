import {
    Button,
    Flex,
    Image,
    InfoIcon,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import { Date, MoreItem } from "../RowsExpenseDetail";
import styled, { css } from "styled-components";
import { PanelRightContractFilled } from "@fluentui/react-icons";
import { currency_code_to_symbols } from "../../../../../constants/maths";
import BadgeCounterCommentaire from "../../Badges/BadgeCounterCommentaire";
import { str_ellipsis } from "../../../../../utils/functions";
import { SIZES } from "../../../../../constants/responsive";
import { useMediaQuery } from "react-responsive";

const RowExpense = ({
    el,
    index,
    note_status,
    id_report,
    is_title_clickable,
    count_messages,
    attached_document,
    handleClickCommentButton,
    refetch_get_all_expenses,
    can_i_delete,
    disableMoreItem = false,

    selected = null,

    onClickTitle = () => null,
}) => {
    const is_km_form =
        el?.category?.form_type === "km_fr" ||
        el?.category?.form_type === "km_ch";

    const isMobileS = useMediaQuery({
        query: `(max-width: ${SIZES.mobileM})`,
    });

    const isMobileM = useMediaQuery({
        query: `(max-width: ${SIZES.mobileL})`,
    });
    const isMobileL = useMediaQuery({
        query: `(max-width: ${SIZES.tablet})`,
    });
    const isMobile = isMobileS || isMobileM || isMobileL;

    return (
        <FlexRow
            fill
            space="between"
            vAlign="center"
            key={"expense_" + index}
            $selected={selected}
            styles={{
                borderBottom: "1px solid #D1D1D1",
                paddingBlock: "8px",
                gap: "6px",
            }}
        >
            {/* date */}
            <Flex styles={{ width: "80px" }}>
                <Date>{moment(el?.date).format("DD/MM")}</Date>
            </Flex>
            {/* name */}
            <Flex
                styles={{ width: "227px" }}
                gap="gap.small"
                onClick={() => {
                    onClickTitle({
                        id_expense: el?.id,
                        id_report: id_report,
                        form_type: el?.category?.form_type,
                    });

                    return;
                }}
            >
                <Flex gap="gap.small">
                    <Image src={el?.category?.icon_url} width="20px" />
                    <Title truncated isCursorPointer={!disableMoreItem}>
                        {str_ellipsis({
                            str: el?.name,
                            length: isMobile ? 10 : 15,
                        })}
                    </Title>
                </Flex>
                {!isMobile && (
                    <BadgeCounterCommentaire count={count_messages} />
                )}
            </Flex>

            {/* HT */}

            {!isMobile && (
                <Flex styles={{ width: "150px" }}>
                    <Text>
                        {currency_code_to_symbols({
                            amount:
                                el?.declared_amount -
                                el?.vats?.at(0)?.declared_amount,
                            currency: el?.currency,
                        })}
                    </Text>

                    {/* <Flex styles={{ width: "250px" }}>
                        {isMobile ? null : (
                            <Text
                                content={
                                    el?.declared_amount -
                                    el?.vats?.at(0)?.declared_amount
                                }
                            />
                        )}
                    </Flex> */}
                </Flex>
            )}
            {/* tva & taux */}

            {/* total */}
            {!isMobile && (
                <Flex styles={{ width: "183px" }}>
                    <Flex gap="gap.smaller" vAlign="center">
                        <TextTotal>
                            {is_km_form
                                ? el?.bareme
                                : currency_code_to_symbols({
                                      amount: el?.vats?.at(0)?.declared_amount,
                                      currency: el?.currency,
                                  })}
                        </TextTotal>
                        {/* default_mileage_allowance_rate */}
                        <Tooltip
                            trigger={<InfoIcon size="small" />}
                            content={
                                is_km_form ? (
                                    <Flex column gap="gap.medium">
                                        <Text
                                            content={`${el?.custom_fields["departure-place"]} - ${el?.custom_fields["arrival-place"]}`}
                                        />
                                        <Text
                                            content={
                                                el?.custom_fields["distance"] +
                                                " Km"
                                            }
                                        />
                                    </Flex>
                                ) : el?.vats?.at(0)?.value === -1 ? (
                                    "Autre ou multi-taux"
                                ) : (
                                    el?.vats?.at(0)?.value + " %"
                                )
                            }
                            pointing
                            position="above"
                            align="top"
                            subtle={false}
                        />
                    </Flex>
                </Flex>
            )}

            {/* image */}
            {!isMobile && (
                <Flex styles={{ width: "128px" }}>
                    <Text>
                        {currency_code_to_symbols({
                            amount: el?.declared_amount,
                            currency: el?.currency,
                        })}
                    </Text>
                </Flex>
            )}
            {/* comment */}

            <Flex gap="gap.medium" vAlign="center">
                {isMobile && (
                    <Text>
                        {currency_code_to_symbols({
                            amount: el?.declared_amount,
                            currency: el?.currency,
                        })}
                    </Text>
                )}
                {(el.comment || attached_document) && (
                    <Button
                        icon={
                            <PanelRightContractFilledStyled
                                color="var(--color-primary)"
                                cursor="pointer"
                            />
                        }
                        size="medium"
                        onClick={handleClickCommentButton}
                        data_expense={el}
                        disabled={!el?.comment && !attached_document}
                        iconOnly
                        text
                    />
                )}
            </Flex>
            {/* delete */}
            <Flex styles={{ width: "40px" }} hAlign="end">
                <MoreItem
                    key={"moteItem_" + index}
                    id_expense={el?.id}
                    disabled={disableMoreItem}
                    refetch_get_all_expenses={refetch_get_all_expenses}
                    onClickUpdate={() =>
                        onClickTitle({
                            id_expense: el?.id,
                            id_report: id_report,
                            form_type: el?.category?.form_type,
                        })
                    }
                    hide_delete_button={!can_i_delete}
                />
            </Flex>
        </FlexRow>
    );
};

export default RowExpense;

const Title = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    cursor: ${({ isCursorPointer }) => (isCursorPointer ? "pointer" : "auto")};
    color: #5b5fc7;
`;

const TextTotal = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;

    color: #242424;
`;

const PanelRightContractFilledStyled = styled(PanelRightContractFilled)`
    min-width: 25px;
    min-height: 25px;
`;

export const FlexRow = styled(Flex)`
    border-left: 4px solid transparent;
    padding-left: 8px;
    ${({ $selected }) =>
        $selected
            ? css`
                  border-left: 4px solid #9299f7;
                  background: #f5f5f5;
              `
            : ""}
    &:hover {
        border-left: 4px solid #9299f7;
        background: #f5f5f5;
    }
    &:hover .cell_amount {
        color: var(--color-primary);
    }
`;
