import React from "react";
import {
    Button,
    Checkbox,
    Divider,
    Flex,
    RadioGroupItem,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

import { Ball, Number, TextPrimary } from "../styles";
import styled from "styled-components";
import { ROLES_CODE } from "../../../constants/roles";
import {
    f_resetStepperContext,
    useRole,
    useSetRole,
    useSetStepRole,
    useStepper,
} from "../stores";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useMutation } from "react-query";
import { _api_save_step_roles } from "../api";
import { useState } from "react";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";

const StepRole = () => {
    const navigate = useNavigate();
    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );
    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();
    const context_set_role = useSetStepRole();
    const context_get_role = useStepper((state) => state.step_roles);
    const [roles, setRoles] = useState(
        context_get_role ?? {
            employee: true,
            manager: false,
            hr: false,
        }
    );

    const _handleSubmit = () => {
        const array_roles = [ROLES_CODE.employee];
        if (roles.hr) array_roles.push(ROLES_CODE.hr);
        if (roles.manager) array_roles.push(ROLES_CODE.manager);
        mutate_save_roles(
            {
                roles: array_roles,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        context_set_role(roles);
                        return navigate(`${ROUTES.employee_add}/fourth`);
                    } else {
                        // TODO : show error toast
                    }
                },
            }
        );
    };

    const { mutate: mutate_save_roles, isLoading: is_saving_roles } =
        useMutation((data) => _api_save_step_roles(data));

    return (
        <Flex column styles={{ maxWidth: "720px" }}>
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        3
                    </Number>
                </Ball>
                <TextPrimary>
                    {t(`pages.add_employee.step_role.title`)}
                </TextPrimary>
                <Text styles={{ color: "red" }}>*</Text>
            </Flex>
            <Flex
                column
                styles={{ marginTop: "24px", maxWidth: "536px" }}
                gap="gap.smaller"
            >
                <Flex>
                    <TextHeaderList>
                        {t(`pages.add_employee.step_role.sub_title`)}
                    </TextHeaderList>
                </Flex>
                <DividerStyle />
                <Flex column gap="gap.small">
                    <Flex column>
                        <Checkbox
                            label={
                                <TextRadio>
                                    {t(
                                        `pages.add_employee.step_role.radios.employee.title`
                                    )}
                                </TextRadio>
                            }
                            checked
                            disabled
                        />
                        <TextRadioDescription>
                            {t(
                                `pages.add_employee.step_role.radios.employee.description`
                            )}
                        </TextRadioDescription>
                    </Flex>
                    <DividerStyle />
                    <Flex column>
                        <Checkbox
                            label={
                                <TextRadio>
                                    {t(
                                        `pages.add_employee.step_role.radios.manager.title`
                                    )}
                                </TextRadio>
                            }
                            onChange={(e, { checked }) => {
                                return setRoles((state) => ({
                                    ...state,
                                    manager: checked,
                                }));
                            }}
                            defaultChecked={roles?.manager}
                        />
                        <TextRadioDescription>
                            {t(
                                `pages.add_employee.step_role.radios.manager.description`
                            )}
                        </TextRadioDescription>
                    </Flex>
                    <DividerStyle />
                    <Flex column>
                        <Checkbox
                            label={
                                <TextRadio>
                                    {t(
                                        `pages.add_employee.step_role.radios.hr.title`
                                    )}
                                </TextRadio>
                            }
                            onChange={(e, { checked }) => {
                                return setRoles((state) => ({
                                    ...state,
                                    hr: checked,
                                }));
                            }}
                            defaultChecked={roles?.hr}
                        />
                        <TextRadioDescription>
                            {t(
                                `pages.add_employee.step_role.radios.hr.description`
                            )}
                        </TextRadioDescription>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                fill
                space="between"
                styles={{
                    marginTop: "200px",
                }}
            >
                <Flex>
                    <DialogCancelConfirmation
                        open={openDialogConfirmation}
                        cancelButton={{
                            content: "Annuler",
                            flat: true,
                        }}
                        confirmButton={{
                            content: "Annuler l’ajout d’un collaborateur",
                            flat: true,
                            isLoading: is_mutate_user,
                            styles: {
                                backgroundColor: "var(--color-danger)",
                            },
                        }}
                        content={
                            "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                        }
                        trigger={
                            <Button
                                content={{
                                    content: "Annuler l'ajout",
                                    styles: {
                                        color: "var(--color-danger)",
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setOpenDialogConfirmation(true);
                                }}
                                text
                            />
                        }
                        header="Êtes-vous sûr.e de annuler l’ajout d’un collaborateur ?"
                        onClose={() => setOpenDialogConfirmation(false)}
                        onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            // reset the context and other data.
                            const id_user = context_step_informations?.id_user;
                            if (id_user) {
                                mutate_delete_user(
                                    {
                                        id_user:
                                            context_step_informations?.id_user,
                                    },
                                    {
                                        onSuccess: (data) => {
                                            if (data?.success) {
                                                f_resetStepperContext();
                                                setOpenDialogConfirmation(
                                                    false
                                                );
                                                navigate("/dashboard");
                                                return;
                                            } else {
                                                console.log(
                                                    "Une erreur est survenue !"
                                                );
                                            }
                                        },
                                        onError: (err) => {
                                            return console.log({ err });
                                        },
                                    }
                                );
                            } else {
                                setOpenDialogConfirmation(false);
                                f_resetStepperContext();
                                navigate("/dashboard");
                            }
                            return;
                        }}
                    />
                </Flex>
                <Flex gap="gap.small">
                    <Button
                        content="Revenir à l'étape précédente"
                        onClick={() =>
                            navigate(`${ROUTES.employee_add}/second`)
                        }
                        secondary
                        flat
                    />
                    <Button
                        content="Passer à l'étape suivante"
                        // disabled={!isValid}
                        loading={is_saving_roles}
                        onClick={_handleSubmit}
                        primary
                        flat
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};

export default StepRole;

const DividerStyle = styled(Divider)`
    margin-bottom: 0px !important;
`;

const TextRadioDescription = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #605e5c;
`;

const TextRadio = styled(Text)`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #252423;
`;

const TextHeaderList = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #242424;
`;
