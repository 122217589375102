import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as animationData from "../../../../assets/lotties/birthday.json";
import Lottie from "react-lottie";
import celebration from "./celebration.svg";
import Sharepoint from "./Sharepoit.svg";

export default function ContractData() {
    return (
        <Flex gap="gap.large" hAlign="center" column>
            <CardContainer column>
                <Title content={"Début du contrat en cours"} />
                <Flex gap="gap.large" column hAlign="center">
                    <SmallCard />
                </Flex>
            </CardContainer>
            <Button
                styles={{ marginBottom: "40px" }}
                size="medium"
                icon={<Image src={Sharepoint} />}
                content="Accéder aux documents"
            />
        </Flex>
    );
}

const SmallCard = () => {
    const [startAnimationConfetti, setStartAnimationConfetti] = useState(false);
    useEffect(() => {
        if (startAnimationConfetti) {
            const id = setTimeout(() => {
                setStartAnimationConfetti(false);
            }, 5000);
            return () => {
                clearTimeout(id);
                return;
            };
        }
    }, [startAnimationConfetti]);
    return (
        <Flex column gap="gap.medium">
            <Flex gap="gap.large">
                <Card background={"#e8ebfa"} column>
                    <Label fontSize={"18px"} content="01" />
                    <Description content="Sept.2021" />
                </Card>
                <Card column>
                    <Label content="Ancienneté" />
                    <Description content="3 ans et 2 mois" />
                </Card>
                <CelebrationCercle
                    vAlign="center"
                    hAlign="center"
                    onClick={() => setStartAnimationConfetti(true)}
                >
                    <Image
                        // styles={{ width: "30px", height: "30px" }}
                        src={celebration}
                    />
                </CelebrationCercle>

                {startAnimationConfetti && (
                    <Lottie
                        options={{
                            loop: false,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                            },
                        }}
                        height={"100vh"}
                        width={400}
                        style={{
                            position: "absolute",
                            inset: "0",
                        }}
                    />
                )}
            </Flex>
            <Flex gap="gap.large">
                <Card column>
                    <Label content="Fin de période d'essai" />
                    <Description content="25/12/2022" />
                </Card>
                <Card column>
                    <Label content="Fin du contrat" />
                    <Description content="30/07/2024" />
                </Card>
            </Flex>
        </Flex>
    );
};

const CelebrationCercle = styled(Flex)`
    cursor: pointer;
    border: 2px solid #c5cbfa;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const Title = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
const Description = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const Label = styled(Text)`
    color: #616161;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
    width: 115px;
    font-style: normal;
    font-weight: ${({ fontSize }) => (fontSize ? 700 : 400)};
    line-height: 16px;
`;

const CardContainer = styled(Flex)`
    gap: 16px;
    padding: 24px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.11),
        0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
`;

const Card = styled(Flex)`
    width: 120px;
    padding: 8px 12px;
    gap: 8px;
    height: 54px;
    border-radius: 2px;
    background: ${({ background }) => (background ? background : "#fff")};
`;
