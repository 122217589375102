import React, { useEffect, useState } from "react";
import { Container, ContainerFluid } from "../../common/styles";
import CustomBreadCrumb from "../../components/breadcrumb";
import { NAV_LIST, TABS_ONBOARDING } from "./constant";
import {
    AddIcon,
    Button,
    Flex,
    Loader,
    ParticipantAddIcon,
} from "@fluentui/react-northstar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import SubHeader from "../../components/breadcrumb/SubHeader";
import MainContentOnboarding from "./components/MainContentOnboarding";
import { _getAllAgencys } from "./api";
import { useQuery } from "react-query";

export default function NewOnboarding() {
    useEffect(() => {
        document.title = "Onboarding des collaborateurs";
    }, []);
    const [TabView, setTabView] = useState(null);
    const { isLoading, data, error, isFetching } = useQuery(
        ["agencys"],
        () => _getAllAgencys(),
        {
            onSuccess: (data) => {
                return setTabView(data[0]?.id);
            },
        }
    );
    const navigate = useNavigate();
    const menuItems = data?.map((agency) => ({
        content: agency.name,
        value: agency.id,
    }));

    if (isLoading || isFetching) {
        return <Loader label="Chargement de la page ....." />;
    }

    return (
        <ContainerFluid fill column>
            <CustomBreadCrumb
                navList={NAV_LIST}
                actions={
                    <Button
                        content={"Ajouter un collaborateur"}
                        icon={<ParticipantAddIcon outline />}
                        iconPosition="after"
                        onClick={() => navigate(ROUTES.employee_add)}
                        flat
                    />
                }
            />
            {data && (
                <>
                    <SubHeader
                        // defaultTab={data[0].id}
                        fromOnboarding={true}
                        menu={data ? menuItems : []}
                        setTabView={setTabView}
                    />

                    <Flex fill>
                        <MainContentOnboarding AgencyId={TabView} />
                    </Flex>
                </>
            )}
        </ContainerFluid>
    );
}
