import React from "react";
import {
    Alert,
    Animation,
    Button,
    Flex,
    Provider,
    Text,
} from "@fluentui/react-northstar";

import { DocumentCopy20Regular } from "@fluentui/react-icons";
import { LinkText } from "./style";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import styled, { keyframes } from "styled-components";

const CopyText = ({
    content,
    width = "300px",
    noBorder = false,
    noAlert = false,
}) => {
    const [copied, setCopyToClipBoard] = useCopyToClipboard(content);

    const copiedOnClick = () => {
        if (noAlert) {
            return;
        }
        setCopyToClipBoard(content);
        return;
    };

    return (
        <Flex
            vAlign="center"
            space="between"
            styles={{ width, overflow: "hidden" }}
        >
            {copied && (
                <AlertCopied
                    copied={copied + ""}
                    content={"Élément copié !"}
                    className={copied ? "fade-in-right" : "fade-out-right"}
                />
            )}
            {content ? <LinkText content={content} /> : <Text content="-" />}
            {content && (
                <Button
                    onClick={copiedOnClick}
                    iconOnly
                    icon={<DocumentCopy20Regular />}
                    flat
                    text={noBorder}
                />
            )}
        </Flex>
    );
};

export default CopyText;

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    right : 0px;
    }
    100% {
    opacity: 1;
    right: 45%
  }
`;

const fadeOutRight = keyframes`
    0% {
        opacity: 1;
        right: 45%;
    }
    100% {
        opacity: 0;
        right: 0px;
    }
`;

const AlertCopied = styled(Alert)`
    position: absolute;
    top: 50%;
    background-color: black;
    color: white;
    font-weight: 600;
    height: 36px;

    opacity: 0;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &.fade-in-right {
        animation-name: ${fadeInRight};
    }

    &.fade-out-right {
        animation-name: ${fadeOutRight};
    }
`;
