import {
    Avatar,
    Button,
    Divider,
    Flex,
    Image,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import React from "react";
import { useLocation } from "react-router-dom";
import {
    BuildingBank16Filled,
    PeopleTeam16Filled,
} from "@fluentui/react-icons";
import ContractData from "./ContractData";
import OnboardingActions from "./OnboardingActions";
export default function SuiviUserDetail() {
    const location = useLocation();
    const { user } = location.state;
    const DepartementName = user.department.name;
    console.log({ user, DepartementName });
    const userAgency = [
        {
            name: "AsendIT Chambéry",
            icon: <BuildingBank16Filled />,
        },
        {
            name: DepartementName,
            icon: <PeopleTeam16Filled />,
        },
    ];
    return (
        <MainContent space="between" column fill>
            <Flex vAlign="start" fill space="between">
                <Flex vAlign="center" gap="gap.medium">
                    <Avatar size="larger" name={user?.name} />
                    <Flex gap="gap.small" column>
                        <UserName content={user?.name} />
                        <Flex gap="gap.medium">
                            {userAgency.map((agency, index) => (
                                <AgencyDepartementFlex
                                    vAlign="center"
                                    key={index}
                                    // gap="gap.small"
                                >
                                    {agency.icon}
                                    <Divider vertical size={3} />
                                    <DepartementNameText
                                        content={agency.name}
                                    />
                                </AgencyDepartementFlex>
                            ))}
                        </Flex>
                    </Flex>
                </Flex>
                <StatusBadge status="Suivi" />
            </Flex>
            <Flex space="between">
                <ContractData />
                <OnboardingActions />
            </Flex>
        </MainContent>
    );
}

const StatusBadge = ({ status }) => {
    return (
        <BadgeFlex vAlign="center" hAlign="center">
            <BadgeText content={status} />
        </BadgeFlex>
    );
};
const BadgeText = styled(Text)`
    color: #4f52b2;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
`;
const BadgeFlex = styled(Flex)`
    width: 106px;
    height: 24px;
    padding: 4px 8px;
    gap: 4px;
    border-radius: 4px;
    background: #e8ebfa;
`;

const UserName = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const AgencyDepartementFlex = styled(Flex)`
    border-radius: 4px;
    background: #ebebeb;
    padding: 4px 8px;
`;
const DepartementNameText = styled(Text)`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const MainContent = styled(Flex)`
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
    background-color: #fff;
    width: 1200px;
    /* height: 463px; */
    gap: 40px;
    padding: 24px 32px;
`;
