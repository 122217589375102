import {
    Avatar,
    Button,
    Dialog,
    Divider,
    EmailIcon,
    ExpandIcon,
    Flex,
    MenuButton,
    MoreIcon,
    PersonIcon,
    SendIcon,
    Table,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import { str_ellipsis } from "../../../utils/functions";
import Shimmering from "./Shimmering";
import EmptyData from "./EmptyData";
import Toast from "../../../components/Alerts";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { _relanceWelcomeMail } from "../api";

const tableHeaders = {
    key: "header_table",
    style: { color: "red" },
    items: [
        {
            key: "header1",
            content: "Collaborateur",
            style: {
                textAlign: "start",
                width: "200px",
                // fontFamily: "Segoe UI",
            },
        },
        {
            key: "header2",
            content: "Département",
        },
        {
            key: "header3",
            content: "Début du contrat",
        },
        {
            key: "header4",
            content: "Fin période d'essai",
        },
        {
            key: "header5",
            content: "Statut",
        },
        {
            key: "header6",
            content: "Tâches restantes",
        },
    ],
};

const EmployeeTable = ({ users, TabView, AgencyId }) => {
    const [archiver, setArchiver] = useState(true);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    console.log({ TabView });

    const navigate = useNavigate();

    setTimeout(() => {
        setLoading(false);
    }, 2000);

    // relancer un mail de bienvenue
    const {
        mutate: mutate_relance_welcome_mail,
        isLoading: is_loading_mutate_relance_welcome_mail,
    } = useMutation((data) => _relanceWelcomeMail(data));

    const _relance_welcome_mail = (user) => {
        // mutate_relance_welcome_mail
        const { name } = user;
        mutate_relance_welcome_mail(user, {
            onSuccess: (response) => {
                if (response?.success) {
                    setToastConfig({
                        visible: true,
                        content: "Mail de bienvenue relancé ",
                        level: "success",
                    });
                } else {
                    setToastConfig({
                        visible: true,
                        content: response?.message,
                        // content: "Erreur lors de l'envoi du mail de bienvenue",
                        level: "danger",
                    });
                }
            },
        });
    };
    // redirection vers la page de détail de l'utilisateur si le statut est "suivi"
    // sinon redirection vers la page de détail de l'onboarding
    const _detailUser = (user) => {
        const { status } = user.onboarding_status;
        console.log({ status19: status });
        status === "suivi"
            ? navigate("/onboarding/suivi/" + user.id, {
                  state: { user: user },
              })
            : navigate("/onboarding/detail/" + user.id, {
                  state: { user_id: user?.id, user, TabView, AgencyId },
              });
    };
    console.log({ AgencyId });
    const _editProfile = (user) => {
        // navigate("/employee/edit/" + user.id);
        // console.log({ editProfileFontion: user });
        alert({ editProfileFontion: user });
    };

    const archiveAccount = (user) => {
        console.log({ user232: user });
        // setArchiver(false);
        setToastConfig({
            visible: true,
            content: "Le compte a bien été archivé",
            level: "success",
        });
    };

    const menu = (TabView, user) => {
        switch (TabView) {
            case 0:
                return [
                    <Button
                        loading={is_loading_mutate_relance_welcome_mail}
                        styles={{
                            width: "150px",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                        content={<Text content="Relancer" />}
                        id="button_remind"
                        icon={<EmailIcon />}
                        onClick={(_, e) => {
                            _relance_welcome_mail(user); // Passe l'événement et l'utilisateur à la fonction
                        }}
                        text
                    />,
                    <Button
                        styles={{
                            textAlign: "left",
                        }}
                        content="Modifier le profil"
                        id="button_Edit_profil"
                        icon={<PersonIcon />}
                        loading={false}
                        onClick={(_, e) => {
                            _editProfile(user);
                        }}
                        text
                    />,
                ];
            case 1:
                return [
                    <Button
                        styles={{
                            width: "180px",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                        content={"Modifier le profil"}
                        id="button_remind"
                        icon={<EmailIcon />}
                        loading={false}
                        onClick={(_, e) => {
                            _editProfile(user);
                        }}
                        text
                    />,
                    <Dialog
                        onConfirm={() => {
                            archiveAccount(user);
                        }}
                        cancelButton="Annuler"
                        confirmButton="Archiver le compte"
                        header="Voulez vous vraiment archiver ce compte ?"
                        content="Le compte ne sera plus visible dans les listes de collaborateur, il ne sera pas supprimer de la base de données."
                        trigger={
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setArchiver(true);
                                }}
                                icon={<PersonIcon />}
                                text
                                styles={{
                                    width: "180px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                id="button_archiver_le_compte"
                                content="Archiver le compte"
                            />
                        }
                    />,
                    // <Button
                    //     styles={{
                    //         width: "180px",
                    //         display: "flex",
                    //         justifyContent: "flex-start",
                    //     }}
                    //     content="Archiver le compte"

                    //     icon={<PersonIcon />}
                    //     loading={false}
                    //     onClick={(_, e) => {
                    //         alert("Archiver le compte", user);
                    //     }}
                    //     text
                    // />,
                ];

            default:
                return [
                    <Button
                        styles={{
                            width: "150px",
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                        content={<Text content="Relancer" />}
                        id="button_remind"
                        icon={<EmailIcon />}
                        loading={false}
                        onClick={(_, e) => {
                            _relance_welcome_mail(user); // Passe l'événement et l'utilisateur à la fonction
                        }}
                        text
                    />,
                    <Button
                        styles={{
                            textAlign: "left",
                        }}
                        content="Modifier le profil"
                        id="button_Edit_profil"
                        icon={<PersonIcon />}
                        loading={false}
                        onClick={(_, e) => {
                            _editProfile(user);
                        }}
                        text
                    />,
                ];
        }
    };

    return !users?.length ? (
        // when users is empty
        <Flex column fill hAlign="center" vAlign="center">
            <TableStyle>
                <TableHeader>
                    <Tr>
                        {tableHeaders?.items?.map((head) => {
                            return (
                                <TableCell
                                    FontWeight={"600"}
                                    key={head?.key}
                                    style={head?.style}
                                >
                                    {head?.content}
                                </TableCell>
                            );
                        })}
                    </Tr>
                </TableHeader>
            </TableStyle>
            <Flex>
                <EmptyData />
            </Flex>
        </Flex>
    ) : (
        // users is not empty
        <Flex column>
            <Toast
                visible={toastConfig?.visible}
                content={toastConfig?.content}
                level={toastConfig?.level}
                onStatusChange={() => {
                    return setToastConfig({
                        visible: false,
                        content: "",
                        level: "success",
                    });
                }}
            />
            <TableStyle>
                <TableHeader>
                    <Tr>
                        {tableHeaders?.items?.map((head) => {
                            return (
                                <TableCell
                                    FontWeight={"600"}
                                    key={head?.key}
                                    style={head?.style}
                                >
                                    {head?.content}
                                </TableCell>
                            );
                        })}
                    </Tr>
                </TableHeader>

                <tbody>
                    {users?.map((user) => {
                        const {
                            name,
                            avatar,
                            department,
                            start_date,
                            probationary_period_end_date,

                            task_count,
                        } = user;
                        return (
                            <tr
                                onClick={(e) => {
                                    _detailUser(user);
                                }}
                            >
                                <TableCell>
                                    {loading ? (
                                        <Shimmering avatar={true} width={200} />
                                    ) : (
                                        <EmployeeInfo
                                            vAlign="center"
                                            gap={"gap.small"}
                                        >
                                            <Avatar
                                                name={name}
                                                image={avatar}
                                                // src={avatar}
                                                alt={name}
                                            />
                                            <NameText
                                                content={str_ellipsis({
                                                    str: name,
                                                    length: 20,
                                                })}
                                            />
                                        </EmployeeInfo>
                                    )}
                                </TableCell>
                                <TableCell>
                                    {loading ? (
                                        <Shimmering width={200} />
                                    ) : (
                                        <Tag
                                            content={
                                                <Flex
                                                    styles={{
                                                        gap: "4px",
                                                        paddingInline: "8px",
                                                        width: "156px",
                                                    }}
                                                    vAlign="center"
                                                >
                                                    {department.icon}
                                                    <Divider
                                                        vertical
                                                        styles={{
                                                            width: "10px",
                                                            height: "20px",
                                                        }}
                                                    />
                                                    <DepartementName
                                                        content={
                                                            department.name
                                                        }
                                                    />
                                                </Flex>
                                            }
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {loading ? (
                                        <Shimmering width={200} />
                                    ) : (
                                        <Text
                                            content={moment(start_date).format(
                                                "DD/MM/YYYY"
                                            )}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {loading ? (
                                        <Shimmering width={200} />
                                    ) : (
                                        <Text
                                            content={moment(
                                                probationary_period_end_date
                                            ).format("DD/MM/YYYY")}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    {loading ? (
                                        <Shimmering width={200} />
                                    ) : (
                                        <Tag
                                            bgColor="#e0e0ff"
                                            color="#4f4fdf"
                                            content={
                                                user?.onboarding_status
                                                    ?.status_text
                                            }
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Flex
                                        vAlign="center"
                                        hAlign="end"
                                        gap="gap.large"
                                    >
                                        <Flex hAlign="end">
                                            {loading ? (
                                                <Shimmering width={50} />
                                            ) : (
                                                <TaskCount>
                                                    {task_count}
                                                </TaskCount>
                                            )}
                                        </Flex>

                                        <MenuButton
                                            // open={open}
                                            on="click"
                                            onOpenChange={(e, { open }) => {
                                                e.stopPropagation();
                                                setOpen(open);
                                            }}
                                            trigger={
                                                <Button
                                                    flat
                                                    iconOnly
                                                    icon={<MoreIcon />}
                                                    title="Open MenuButton"
                                                />
                                            }
                                            menu={menu(TabView, user)}
                                        />
                                    </Flex>
                                </TableCell>
                            </tr>
                        );
                    })}
                </tbody>
            </TableStyle>
        </Flex>
    );
};

export default EmployeeTable;

const DepartementName = styled(Text)`
    color: #616161;
    font-family: Segoe UI;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const Tr = styled.tr`
    background-color: #f5f5f5;
`;

const NameText = styled(Text)`
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
`;

const TableStyle = styled.table`
    width: 100%;
    height: 100%;

    border-collapse: collapse;
    margin: 0 auto;
    /* border: 2px solid purple; */
`;

const TableHeader = styled.thead`
    width: 150px;
    text-align: left;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
    border-radius: 4px;
`;

const TableCell = styled.td`
    width: 245px;

    padding: 16px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    text-align: center;
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: ${({ FontWeight }) => (FontWeight ? FontWeight : "400")};
    line-height: 24px;
`;

const EmployeeInfo = styled(Flex)`
    width: 200px;
`;

const Tag = styled(Text)`
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: ${({ bgColor }) => bgColor || "#f0f0f0"};
    color: ${({ color }) => color || "#000"};
`;

const TaskCount = styled(Text)`
    background-color: #3d3e78;
    color: white;
    width: 24px;
    height: 24px;
    /* margin-left: 30%; */
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
