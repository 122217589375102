import React, { useRef, useState } from "react";
import {
    Avatar,
    Button,
    EmailIcon,
    Flex,
    Input,
    LinkedInIcon,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import { PeopleTeam16Regular } from "@fluentui/react-icons";

import BadgeIcon from "../../BadgeIcon";
import CopyText from "../../CopyText";
import CustomBadge from "../../../../../components/Badge/Custom";
import { Link } from "react-router-dom";
import CallMobile from "../../CallMobile";
import { _get_str_status } from "../../../utils";
import { useMutation, useQueryClient } from "react-query";
import { _api_upload_avatar } from "../../../../../api/user/profile";
import { useAuthDetailStore } from "../../../../../stores/useAuthStore";
import { set } from "react-hook-form";
import { QUERY_KEY } from "../../../constants";

const Header = ({
    full_name,
    status,
    avatar,
    poste,
    departement,
    headOffice,
    email,
    mobile,
    linkedin,
    matricule,
    isExternal,
    missingInformationsNumber,
    isMe = false,
    isHR = false,
}) => {
    const refInputFile = useRef(null);

    const clientQuery = useQueryClient();

    const { agency } = useAuthDetailStore((state) => state.detail);

    const { isLoading: isLoadingAvatar, mutate: mutateAvatar } = useMutation(
        (data) => _api_upload_avatar(data)
    );

    const _uploadAvatar = ({ file }) => {
        const id_user = useAuthDetailStore.getState().id_user;
        mutateAvatar(
            {
                uploaded_files: file,
                id_user,
            },
            {
                onSuccess: (data) => {
                    if (data?.success) {
                        clientQuery.refetchQueries(QUERY_KEY + "::header");
                    }
                    return;
                },
            }
        );
        return;
    };

    return (
        <Flex
            fill
            column
            styles={{
                overflow: "hidden",
            }}
        >
            {/* INPUT HIDDEN */}
            <Input
                type="file"
                hidden
                ref={refInputFile}
                onChange={(e) => {
                    const files = e?.target?.files;
                    if (!files?.length) {
                        return;
                    }
                    const file = files[0];
                    if (file?.size > 2_500_000) {
                        return console.log("max size exceeded");
                    }
                    _uploadAvatar({
                        file,
                    });
                    return;
                }}
            />
            <Flex fill styles={{ height: "160px" }}>
                {/* left section */}
                <Flex fill gap="gap.medium" vAlign="center">
                    <Flex styles={{ position: "relative" }}>
                        <AvatarStyle
                            image={avatar}
                            name={full_name}
                            size={"largest"}
                            className={isMe ? "editable" : ""}
                            onClick={
                                isMe
                                    ? () => refInputFile?.current?.click()
                                    : null
                            }
                        />
                        {isLoadingAvatar && (
                            <Loader
                                variables={{
                                    color: "white",
                                }}
                                styles={{
                                    position: "absolute",
                                    inset: 0,
                                }}
                            />
                        )}
                    </Flex>
                    <Flex column gap="gap.small">
                        <Flex gap="gap.small" vAlign="center">
                            <FullName content={full_name} />
                            <CustomBadge content={_get_str_status(status)} />
                        </Flex>
                        <Flex gap="gap.small">
                            <Poste content={poste} />
                            {isExternal && <External content={"(Externe)"} />}
                        </Flex>
                        <Flex gap="gap.medium">
                            <BadgeIcon
                                icon={departement.icon}
                                content={departement.content}
                            />
                            <BadgeIcon
                                icon={headOffice.icon}
                                content={headOffice.content}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                {/* center section */}
                <Flex
                    fill
                    column
                    hAlign="center"
                    styles={{ padding: "32px 0px" }}
                >
                    <Flex column gap="gap.small">
                        <CopyText content={email} />
                        <CallMobile content={mobile} />
                        {(isMe || isHR) &&
                            missingInformationsNumber !== 0 &&
                            missingInformationsNumber !== undefined && (
                                <MissingInformations
                                    content={
                                        missingInformationsNumber +
                                        " informations manquantes sur le profil"
                                    }
                                />
                            )}
                    </Flex>
                </Flex>
                {/* right section */}
                <Flex fill hAlign="end" styles={{ padding: "32px 24px" }}>
                    {isHR && agency?.country_code?.toLowerCase() !== "ch" && (
                        <div>
                            <BadgeIcon
                                icon={matricule.icon}
                                content={matricule.content}
                            />
                        </div>
                    )}
                </Flex>
            </Flex>
            <Flex fill>
                <Flex gap="gap.medium" fill>
                    <Button
                        styles={{ textDecorationLine: "none" }}
                        as="a"
                        href={`MSTeams:/l/chat/0/0?users=${email}`}
                        content={"Contacter sur Teams"}
                        icon={<PeopleTeam16Regular />}
                        flat
                        primary
                    />
                    <Button
                        styles={{ textDecorationLine: "none" }}
                        as="a"
                        href={`mailto:${email}`}
                        content={"Envoyer un email"}
                        icon={<EmailIcon outline />}
                        flat
                    />
                </Flex>
                <Flex styles={{ paddingRight: "15px" }}>
                    {linkedin && (
                        <Link
                            to={linkedin}
                            target="_blank"
                            style={{
                                textDecorationLine: "none",
                            }}
                        >
                            <Button
                                content={"Voir le profil"}
                                icon={<LinkedInIcon />}
                                iconPosition={"after"}
                                text
                            />
                        </Link>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Header;

const AvatarStyle = styled(Avatar)`
    text-decoration: none;
    border-radius: 49%;
    overflow: hidden;
    text-align: center;
    color: white;
    :hover.editable {
        cursor: pointer;
        ::after {
            content: "";
            position: absolute;
            inset: -10px;
            border: 1px solid white;
            background-color: rgba(37, 36, 35, 0.65);
        }
        ::before {
            z-index: 2;
            content: "Modifier";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            color: white;
        }
    }
`;

const MissingInformations = styled(Text)`
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #c4314b;
`;

const External = styled(Text)``;

const Poste = styled(Text)`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
`;

const FullName = styled(Text)`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #242424;
`;
