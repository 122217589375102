import { Flex, Pill } from "@fluentui/react-northstar";
import React from "react";
import { useFormContext } from "react-hook-form";

const PillListEmployeeSelected = ({ selectedEmployees = [] }) => {
    const { setValue, getValues } = useFormContext();
    return (
        <Flex fill wrap>
            {selectedEmployees?.map((user) => {
                const header = user?.header;
                return (
                    <Pill
                        key={header}
                        onDismiss={(e, data) => {
                            setValue(
                                "employee",
                                getValues("employee")?.filter(
                                    (u) => u?.header !== header
                                )
                            );
                        }}
                        image={user?.image}
                        actionable
                    >
                        {user?.header}
                    </Pill>
                );
            })}
        </Flex>
    );
};

export default PillListEmployeeSelected;
