import {
    Button,
    ChevronStartIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function HeaderUserDetail() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Flex vAlign="center" fill space="between">
            <Flex gap="gap.medium">
                <Button
                    onClick={goBack}
                    flat
                    iconOnly
                    icon={<ChevronStartIcon />}
                />
                <HeaderText content="Détails du collaborateur" />
            </Flex>
            <RetourButton onClick={goBack} content={"Retour"} />
        </Flex>
    );
}

const HeaderText = styled(Text)`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
`;

const RetourButton = styled(Text)`
    color: #5b5fc7;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    cursor: pointer;
`;
