import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Breadcrumb,
    Button,
    ChevronEndMediumIcon,
    CloseIcon,
    Flex,
    MenuIcon,
    Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

import { FlexContainerFluid } from "../../pages/DashboardTasks/style";
import Drawer from "./Drawer";
import { BREADCRUMB_HUMBERGER_GLOBAL } from "../../constants/routes/drawer";
import { I18N_NS_ADD_EMPLOYEE } from "../../features/addEmployee/constants";
import { useAuthStore } from "../../stores/useAuthStore";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { useMediaQuery } from "react-responsive";

export const RENDER_ACTIONS_BREADCRUMB_FOR_ADD_EMPLOYEE = () => {
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    return (
        <Flex>
            <Button
                text
                content={t("common.cancel")}
                styles={{ color: "white" }}
            />
            <Button content={t("pages.add_employee.next_step")} secondary />
        </Flex>
    );
};

const CustomBreadCrumb = ({
    humberger = BREADCRUMB_HUMBERGER_GLOBAL,
    navList,
    hide_drawer,
    actions = "",
    i18n_ns = "translation",
    searchBar,
}) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const { t } = useTranslation(i18n_ns);
    const isLogged = useAuthStore();

    const isMobile = useMediaQuery({
        query: `(max-width:500px)`,
    });

    return (
        <FlexContainerFluid>
            {isLogged?.infos !== null && humberger && !hide_drawer && (
                <Drawer
                    humberger={humberger}
                    open={showDrawer}
                    toggle={setShowDrawer}
                />
            )}
            <Flex
                styles={{
                    backgroundColor: "#5B5FC7",
                    color: "white",
                    height: "50px",
                    paddingInline: "8px",
                    zIndex: 100,
                }}
                fill
            >
                <Flex
                    styles={{
                        maxWidth: "1440px",
                        margin: "0 auto",
                        width: "100%",
                        justifyContent: "space-between",
                    }}
                    vAlign="center"
                >
                    <Breadcrumb aria-label="breadcrumb">
                        {!hide_drawer &&
                        humberger &&
                        isLogged?.infos !== null ? (
                            !showDrawer ? (
                                <MenuIcon
                                    styles={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setShowDrawer((state) => !state)
                                    }
                                />
                            ) : (
                                <CloseIcon
                                    styles={{ cursor: "pointer" }}
                                    onClick={() =>
                                        setShowDrawer((state) => !state)
                                    }
                                />
                            )
                        ) : null}
                        {navList.map((el, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Breadcrumb.Item key={index}>
                                        <Breadcrumb.Link
                                            href={el.href}
                                            styles={{
                                                textDecoration: "none",
                                                color: "white",
                                            }}
                                        >
                                            {index === navList.length - 1 ? (
                                                <b>{t(el.label)}</b>
                                            ) : (
                                                <>
                                                    {el?.icon ? (
                                                        el.label
                                                    ) : isMobile ? (
                                                        <Text
                                                            styles={{
                                                                fontSize:
                                                                    "32px",
                                                                paddingBottom:
                                                                    "16px",
                                                            }}
                                                            content="..."
                                                        />
                                                    ) : (
                                                        <Text
                                                            truncated
                                                            content={t(
                                                                el?.label
                                                            )}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Breadcrumb.Link>
                                    </Breadcrumb.Item>
                                    {index !== navList.length - 1 && (
                                        <Breadcrumb.Divider>
                                            <ChevronEndMediumIcon size="small" />
                                        </Breadcrumb.Divider>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Breadcrumb>
                    {searchBar}
                    {actions ? actions : <Flex></Flex>}
                </Flex>
            </Flex>
        </FlexContainerFluid>
    );
};

CustomBreadCrumb.propTypes = {
    navList: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            label: PropTypes.string,
        })
    ).isRequired,
    actions: PropTypes.element,
    i18n_ns: PropTypes.string, // namespace i18n next for react
};

export default CustomBreadCrumb;
