import {
    Alert,
    Button,
    Dropdown,
    ExclamationTriangleIcon,
    Flex,
    Input,
    RadioGroup,
    Text,
    TextArea,
} from "@fluentui/react-northstar";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { _getAllUsers } from "../../../../../api/user";
import { ErrorMessage } from "../../../../addEmployee/styles";
import { AVATAR_DEFAULT } from "../../../../../constants";

const ContentAjustementDialog = ({
    SoldeToEdit = [],
    allUsers,
    no_politic_leave,
    no_politic_leave_message,
    is_loading_ajust_solde = false,
}) => {
    const groupItems = [
        {
            key: "1",
            label: "Ajout",
            value: "add",
        },
        {
            key: "2",
            label: "Retrait",
            value: "remove",
        },
        {
            key: "3",
            label: "Initialisation",
            value: "initialize",
        },
    ];

    const array_solde = SoldeToEdit?.map((el) => {
        return {
            header: el?.name,
            id: el?.id,
        };
    });

    const {
        register,
        control,
        formState: { errors },
    } = useFormContext({
        defaultValues: {
            id_user: "",
            operation: "",
            leave_type_id: "",
            balance_value: "",
            comment: "",
        },
    });

    return (
        <Flex column styles={{ gap: "16px" }}>
            {/* personne concernée */}
            {no_politic_leave && (
                <Alert
                    visible={no_politic_leave}
                    content={no_politic_leave_message}
                    danger
                    icon={<ExclamationTriangleIcon />}
                    styles={{
                        width: "100%",
                    }}
                />
            )}
            <Section column>
                <Label content={"Personne concernée"} />
                <Controller
                    name="id_user"
                    control={control}
                    rules={{
                        required: "Ce champ est requis",
                    }}
                    render={({ field: { onChange } }) => (
                        <Dropdown
                            search
                            fluid
                            items={allUsers?.map((el) => {
                                return {
                                    header: el?.name,
                                    id: el?.id,
                                    image: el?.avatar || AVATAR_DEFAULT,
                                };
                            })}
                            placeholder="Choisir la personne concernée"
                            checkable
                            onChange={(_, i) => onChange(i?.value?.id)}
                            error={errors?.id_user ? true : false}
                        />
                    )}
                />

                <ErrorMessage content={errors?.id_user?.message} />
            </Section>
            {/* type d'ajustement  */}
            <Section column>
                <Label content="Type d'ajustement" />
                <Controller
                    name="operation"
                    control={control}
                    rules={{
                        required: "Ce champ est requis",
                    }}
                    render={({ field: { onChange } }) => {
                        return (
                            <RadioGroup
                                items={groupItems}
                                onCheckedValueChange={(_, i) =>
                                    onChange(i.value)
                                }
                            />
                        );
                    }}
                    error={errors?.operation}
                />
                <ErrorMessage content={errors?.operation?.message} />
            </Section>
            {/* solde à ajuster  */}
            <Section column>
                <Label content="Solde à ajuster" />
                <Controller
                    name="leave_type_id"
                    control={control}
                    rules={{
                        required: "Ce champ est requis",
                    }}
                    render={({ field: { onChange } }) => {
                        return (
                            <Dropdown
                                fluid
                                items={array_solde}
                                placeholder="Quel solde doit être modifié ?"
                                checkable
                                onChange={(_, i) => onChange(i?.value?.id)}
                                disabled={
                                    no_politic_leave || is_loading_ajust_solde
                                }
                            />
                        );
                    }}
                    error={errors?.leave_type_id}
                />
                <ErrorMessage content={errors?.leave_type_id?.message} />
            </Section>
            {/* Montant de l'ajustement  */}
            <Section column>
                <Label content="Montant de l'ajustement " />
                <Flex vAlign="center">
                    <Flex>
                        <Input
                            {...register("balance_value", {
                                required: "Ce champ est requis",
                            })}
                            style={{ width: "64px" }}
                            fluid
                            type="text"
                            placeholder="--"
                        />
                    </Flex>
                    <Flex
                        vAlign="center"
                        hAlign="center"
                        styles={{
                            width: "48px",
                            height: "32px",
                            background: "#C7C7C7",
                            borderRadius: "0px 4px 4px 0px",
                        }}
                    >
                        <Text content="Jours" />
                    </Flex>
                </Flex>
                {errors?.balance_value && (
                    <ErrorMessage content={errors?.balance_value?.message} />
                )}
            </Section>
            {/* justificatif */}
            <Section column>
                <Label content="Raison (facultatif)" />
                <TextArea
                    // name="justification"
                    required
                    {...register("comment")}
                    placeholder="Ajouter un justificatif"
                />
            </Section>
        </Flex>
    );
};

export default ContentAjustementDialog;

const Label = styled(Text)`
    color: #616161;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const Section = styled(Flex)`
    gap: 4px;
`;
