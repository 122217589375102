import React, { useMemo } from "react";
import { Container, ContainerFluid } from "../../../common/styles";

import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import {
    BuildingBank16Filled,
    PeopleTeam16Filled,
} from "@fluentui/react-icons";
import StatusBadge from "./StatusBadge";
import HeaderDates from "./HeaderDates";
import FooterActions from "./FooterActions";
import CollaboratorTasks from "./CollaboratorTasks";
import HeaderUserDetail from "./commun/HeaderUserDetail";
import { useQuery } from "react-query";
import { _getUserDetail } from "../api";

export default function DetailCollaborator() {
    const location = useLocation();
    const user = location.state;
    console.log(user?.TabView === 3 ? "yesss" : "false");

    const user_id = useMemo(() => user?.user?.id, []);
    console.log({ user123: user_id });

    const { isLoading: isLoadingUserDetail, data: dataUserDetail } = useQuery(
        ["userdetail", user_id],
        () => _getUserDetail(user_id),
        {
            refetchOnWindowFocus: false,
        }
    );

    return (
        <ContainerFluid
            styles={{ backgroundColor: "#F5F5F5" }}
            gap="gap.medium"
            column
        >
            <ContainerStyled space="between">
                <HeaderUserDetail />
            </ContainerStyled>
            <CollaboratorTasks dataUserDetail={dataUserDetail} />
        </ContainerFluid>
    );
}

const ContainerStyled = styled(Container)`
    margin-top: 24px;
    /* margin-bottom: 48px; */
`;
