import {
    Button,
    FilesUploadIcon,
    Flex,
    SignatureIcon,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { CheckmarkStarburst20Filled } from "@fluentui/react-icons";
import styled from "styled-components";
export default function OnboardingActions() {
    const cards = [
        {
            title: "Accords d'entreprise",
            actions: "Signature",
            icon: <SignatureIcon />,
        },
        {
            title: "Contrat de travail",
            actions: "Signature",
            icon: <SignatureIcon />,
        },
        {
            title: "Carte AVS",
            actions: "Envoi",
            icon: <FilesUploadIcon />,
        },
    ];
    return (
        <Main column>
            <Flex
                gap="gap.smaller"
                vAlign="center"
                styles={{
                    background: "#EBEBEB",
                    height: "60px",
                    padding: "0 16px",
                }}
            >
                <CheckmarkStarburst20Filled color="green" />
                <Title content="Actions effectuées en onboarding" />
            </Flex>
            <Flex>
                <Card cards={cards} />
            </Flex>
        </Main>
    );
}

const Card = ({ cards }) => {
    return (
        <Flex styles={{ padding: "0px 16px" }} fill column gap="gap.medium">
            {cards.map((card, index) => (
                <CardContainer vAlign="center" space="between" key={index}>
                    <Flex gap="gap.smaller" vAlign="center">
                        <CheckmarkStarburst20Filled color="green" />
                        <CardTitle content={card.title} />
                    </Flex>
                    <ActionButton vAlign="center" hAlign="center">
                        {card.icon}
                        <TextAction content={card.actions} />
                    </ActionButton>
                </CardContainer>
            ))}
        </Flex>
    );
};

const TextAction = styled(Text)`
    color: #242424;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const ActionButton = styled(Flex)`
    gap: 4px;
    width: 88px;
    border-radius: 4px;
    background: #f5f5f5;
    padding: 4px 8px;
`;

const CardContainer = styled(Flex)`
    border-radius: 8px;
    background: #fff;
    height: 48px;
    padding: 0 16px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const Title = styled(Text)`
    /* width: 239px; */
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;
const CardTitle = styled(Text)`
    overflow: hidden;
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    text-overflow: ellipsis;
    white-space: nowrap;

    /* Medium/600 */
    font-family: "Segoe UI";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
`;

const Main = styled(Flex)`
    /* padding: 14px 16px; */
    background-color: #f0f0f0;
    width: 447px;
`;
