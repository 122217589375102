import React from "react";
import { Container, ContainerFluid } from "../../../common/styles";
import OnboardingSearchBar from "./OnboardingSearchBar";
import Content from "./Content";
import { Flex } from "@fluentui/react-northstar";

export default function MainContentOnboarding({ AgencyId }) {
    return (
        <ContainerFluid fill>
            <Container fill column>
                {/* search bar  */}
                <Flex
                    styles={{
                        paddingBlock: "14px",
                    }}
                    hAlign="center"
                >
                    <OnboardingSearchBar />
                </Flex>
                {/* Main content page  */}
                <Content AgencyId={AgencyId} />
            </Container>
        </ContainerFluid>
    );
}
