import { BASE_URL_API } from "../../../constants";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import fetcher from "../../../utils/fetcher";

export const api_get_new_dashboard_data = () => {
    return fetcher(`${BASE_URL_API}/user/homepage`, {
        method: "GET",
        credentials: "include",
    });
};

export const getAllUser = (user) => {
    return fetcher(`${BASE_URL_API}/users/search?q=${user}`, {
        method: "GET",
        credentials: "include",
    });
};
