import React, { useEffect, useState } from "react";
import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";

import PropTypes from "prop-types";
import { DEVICE } from "../../constants/responsive";

const SliderTabs = ({ tabs, defaultActiveIndex, onChange, ...rest }) => {
    const [active, setActive] = useState(null);

    useEffect(() => {
        setActive(defaultActiveIndex);
    }, [defaultActiveIndex]);

    const _isChecked = (index) => {
        if (active === index) return "active";

        return false;
    };

    return (
        <ContainerSlider gap="gap.small" {...rest}>
            <Wrapper>
                {tabs?.map((el, index) => (
                    <Card key={el + "_" + index}>
                        <TextStyle
                            onClick={() => {
                                setActive(index);
                                onChange(index);
                                return;
                            }}
                            className={_isChecked(index)}
                        >
                            {el}
                        </TextStyle>
                    </Card>
                ))}
            </Wrapper>
        </ContainerSlider>
    );
};

SliderTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultActiveIndex: PropTypes.number,
};

export default SliderTabs;

const ContainerSlider = styled(Flex)`
    background-color: transparent;
    @media ${DEVICE.mobileS} {
        overflow-x: scroll;
        ::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: auto;
    }
`;

const Card = styled(Flex)`
    @media ${DEVICE.mobileS} {
        text-align: center;
        min-width: fit-content;
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        text-align: left;
        width: fit-content;
    }
`;

const TextStyle = styled(Text)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        font-size: 12px;
        text-wrap: nowrap;
    }
    @media ${DEVICE.laptop} {
        width: fit-content;
        font-size: 14px;
        text-wrap: wrap;
    }
`;

const Wrapper = styled(Flex)`
    gap: 4px;
    padding: 4px;
    background-color: #e8ebfa;
    border-radius: 6px;
    color: #4f52b2;
    align-items: center;
    span {
        padding: 4px 8px;
        cursor: pointer;
    }
    .active {
        color: black;
        background-color: white;
        box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
            0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        font-weight: bold;
    }
    span:not(.active):hover {
        color: black;
        padding: 4px 8px;
        background-color: white;
        box-shadow: 0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11),
            0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        opacity: 0.7;
    }
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
    @media ${DEVICE.laptop} {
        width: auto;
    }
`;
