import { Flex } from "@fluentui/react-northstar";
import React from "react";
import {
    CheckboxChildren,
    CheckboxTitle,
    FlexWrapperTitle,
    WrapperChildren,
} from "../styles/stepRole";

import { _checkedChildrens, _checkedHeaderList } from "../utils";
import { useStepper } from "../stores";
import { queryClient } from "../../..";

const RessourceRow = ({ resources }) => {
    const cache_resources = queryClient.getQueryData("getResources");
    // console.log({ context_get_resources });
    const default_children_checked = cache_resources
        ?.map((el) => {
            return el?.resources?.filter((res) => {
                return res.checked === true;
            });
        })
        .flat()
        ?.map((child) => child?.id);

    const default_checked_headers = cache_resources
        ?.filter((el) => {
            return el.checked === true;
        })
        .map((el) => el?.id);

    console.log({ default_checked_headers, default_children_checked });

    return (
        <Flex column styles={{ marginTop: "24px" }}>
            {resources?.map((res, i) => {
                const some_children_checked = cache_resources.some(
                    (el) => el?.checked === true
                );

                const renderChildren = res.resources.map((el, index) => {
                    return (
                        <WrapperChildren vAlign="center" key={index}>
                            <CheckboxChildren
                                label={el.name}
                                checked={el?.checked}
                                onChange={_checkedChildrens}
                                data-parent={res}
                                data-children={el}
                                defaultChecked={default_children_checked?.includes(
                                    el?.id
                                )}
                            />
                        </WrapperChildren>
                    );
                });

                const defaultChecked = default_checked_headers?.includes(
                    res?.id
                )
                    ? true
                    : some_children_checked === true
                    ? "mixed"
                    : false;
                return (
                    <Flex column key={i}>
                        <FlexWrapperTitle>
                            <CheckboxTitle
                                data={res}
                                label={res.name}
                                onChange={_checkedHeaderList}
                                checked={res?.checked}
                                defaultChecked={defaultChecked}
                            />
                        </FlexWrapperTitle>
                        {renderChildren}
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default RessourceRow;
