import { BASE_URL_API } from "../../constants";
import { useStepperContext } from "../../pages/Dashboard/AddCollaborator/ContextAddCollaborateur";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import fetcher from "../../utils/fetcher";

export const _api_get_detail_one_task = ({ id_user, id_task }) => {
    return fetcher(`${BASE_URL_API}/user/${id_user}/task/${id_task}`, {
        method: "GET",
        credentials: "include",
    });
};

export const api_create_individual_task = (data) => {
    return fetcher(`${BASE_URL_API}/task/create`, {
        method: "POST",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const getTemplates = () => {
    return fetcher(BASE_URL_API + "/task/template/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getTasksByTemplates = () => {
    const { templates } = useStepperContext.getState().tasks;
    let query = "";

    // generate ids for query
    if (templates.length) {
        const ids = templates.map((el) => el.id);
        query = "?id=" + ids.join("&id=");
    }

    return fetcher(BASE_URL_API + "/task/template" + query, {
        method: "GET",
        credentials: "include",
    });
};

export const getTaskCategory = () => {
    return fetcher(BASE_URL_API + "/task/category/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getTaskResponsables = () => {
    return fetcher(BASE_URL_API + "/user/list", {
        method: "GET",
        credentials: "include",
    });
};

export const _get_collaborator_tasks = () => {
    const { id } = useAuthDetailStore.getState().detail; // get id user.
    return fetcher(BASE_URL_API + "/user/" + id + "/tasks", {
        method: "GET",
        credentials: "include",
    });
};

export const _api_validate_document = ({
    task_id,
    new_doc_ids,
    assigned_to,
}) => {
    return fetcher(
        `${BASE_URL_API}/user/${assigned_to}/task/${task_id}/upload_document`,
        {
            method: "PATCH",
            body: JSON.stringify({
                new_doc_ids,
            }),
            credentials: "include",
        }
    );
};

export const _api_delete_document = ({ task_id, document_id }) => {
    const { id: id_user } = useAuthDetailStore.getState().detail;
    return fetcher(
        `${BASE_URL_API}/user/${id_user}/task/${task_id}/document/${document_id}/delete`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const _update_status_task = ({ task_id, task_status, body }) => {
    const { id } = useAuthDetailStore.getState().detail; // get id user.
    // return console.log({ body });
    return fetcher(
        BASE_URL_API +
            "/user/" +
            id +
            "/task/" +
            task_id +
            "/status/" +
            task_status,
        {
            method: "PATCH",
            credentials: "include",
            ...(body?.pspdfkit_document_id && { body: JSON.stringify(body) }),
        }
    );
};

export const _upload_file = (formData, addParams = false) => {
    return fetch(
        BASE_URL_API +
            "/upload_file/" +
            (addParams ? "?upload_to_pspdfkit=true" : ""),
        {
            method: "POST",
            body: formData,
            credentials: "include",
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return {
                success: false,
                files: [],
            };
        });
};

//Get task responsable
export const getTaskValidators = (params) => {
    const id_user = params?.id_user;
    console.log({ id_user });
    let { id } = useAuthDetailStore.getState().detail;
    if (id_user !== undefined) {
        id = id_user;
    }
    return fetcher(`${BASE_URL_API}/user/${id}/task_validators/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const getListManagerAndHrOnAgency = () => {
    return fetcher(`${BASE_URL_API}/task_validators/list`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_list_tasks_hr = ({
    page = 1,
    related_user_id = "",
    assigned_user_id = "",
    process = 0,
}) => {
    // config the search query params
    let search_params = ``;
    if (related_user_id) search_params += `&related_user_id=${related_user_id}`;
    if (
        assigned_user_id !== undefined &&
        assigned_user_id !== null &&
        assigned_user_id !== "" &&
        assigned_user_id !== "undefined"
    )
        search_params += `&assigned_user_id=${assigned_user_id}`;

    if (process) search_params += `&process=${process}`;

    return fetcher(
        `${BASE_URL_API}/tasks/hr_view/list?page=${page}${search_params}`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

//Task responsable

//Delete request : Delete task from profile onboarding //

export const _delete_task = async (data) => {
    const user = useAuthStore.getState().infos;
    //url Delete is here

    return fetch(
        BASE_URL_API +
            "/onboarding/user/" +
            data?.isUser +
            "/task/" +
            data?.idTask,
        {
            method: "DELETE",
            headers: {
                "Content-type": "application/json", // The type of data you're sending
                Authorization: "Bearer " + user.access,
            },
            credentials: "include",
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const _api_delete_task = async ({ id_user, task_id }) => {
    return fetcher(
        `${BASE_URL_API}/tasks/hr_view/user/${id_user}/task/${task_id}/delete`,
        {
            method: "DELETE",
            credentials: "include",
        }
    );
};

export const _api_upload_ocr = ({ formData, id_expense }) => {
    const user = useAuthStore.getState().infos;
    return fetch(
        `${BASE_URL_API}/expense_report/${id_expense}/document/upload`,
        {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Bearer ${user?.access}`,
            },
            credentials: "include",
        }
    )
        .then((response) => {
            return response.json();
        })
        .catch((error) => {
            // console.log(error);
            return {
                success: false,
                message: error,
                files: [],
            };
        });
};

// end of delete request

//Modifyrequest : Modify task from profile onboarding //

export const _modify_task = async (data) => {
    const user = useAuthStore.getState().infos;
    //url Modify is here

    return fetch(
        BASE_URL_API +
            "/onboarding/user/" +
            data?.idCollaborator +
            "/task/" +
            data.form.id,
        {
            method: "PUT",
            headers: {
                "Content-type": "application/json", // The type of data you're sending
                Authorization: "Bearer " + user.access,
            },
            body: JSON.stringify(data.form),
            credentials: "include",
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};

// end of Modify task
