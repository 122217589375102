import React, { useState } from "react";
import { useQuery } from "react-query";

import { Box, Flex, Loader } from "@fluentui/react-northstar";
import TaskToValidate from "../../../components/tasks/TaskToValidate";
import EmptyTaskToValidate from "../../../components/tasks/EmptyTaskToValidate";
import Toast from "../../../components/Alerts";
import { _get_collaborator_tasks } from "../../../api/tasks";
import { FlexContainer, WrapperTasksToValidate } from "../style";

const INIT_CONFIG_TOAST = {
    content: "",
    visible: false,
    level: "warning",
};

const MyValidatingTasks = ({ refetchGetAllTasks }) => {
    const [toast, setConfigToast] = useState(INIT_CONFIG_TOAST);

    /**
     * get tasks to validate
     */
    const {
        isLoading: isLoadingTasksToValidate,
        isFetching: isFetchingTasksToValidate,
        data: allTasksToValidate,
        refetch: refetchGetAllTasksToValidate,
    } = useQuery(["getAllTasksToValidate"], () => _get_collaborator_tasks());

    return (
        <Box
            styles={{
                backgroundColor: "#FAFAFA",
                display: "flex",
                flex: "1",
            }}
        >
            <Toast
                visible={toast.visible}
                content={toast.content}
                level={toast.level}
                onStatusChange={() => {
                    setConfigToast(INIT_CONFIG_TOAST);
                    refetchGetAllTasksToValidate();
                    refetchGetAllTasks();
                    return;
                }}
            />
            <FlexContainer fill>
                {allTasksToValidate?.tasks_to_validate?.length !== 0 ? (
                    <WrapperTasksToValidate>
                        {allTasksToValidate?.tasks_to_validate.map(
                            (el, index) => {
                                return (
                                    <TaskToValidate
                                        key={index}
                                        task={el}
                                        setConfigToast={setConfigToast}
                                        refetchGetAllTasks={refetchGetAllTasks}
                                    />
                                );
                            }
                        )}
                    </WrapperTasksToValidate>
                ) : isLoadingTasksToValidate || isFetchingTasksToValidate ? (
                    <Flex
                        fill
                        vAlign="center"
                        hAlign="center"
                        styles={{ paddingTop: "50px" }}
                    >
                        <Loader size="medium" label="Chargement des tâches" />
                    </Flex>
                ) : (
                    <EmptyTaskToValidate />
                )}
            </FlexContainer>
        </Box>
    );
};

export default MyValidatingTasks;
