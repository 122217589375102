import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import { refreshToken } from "../../api/auth";
import LoaderPage from "../loaders/LoaderPage";
import { ROUTES } from "../../constants/routes";

const PrivateRoute = ({ children }) => {
    const { infos: user } = useAuthStore();
    const location = useLocation();
    const [reload, setReload] = useState(false);
    const [reloadOnce, setReloadOnce] = useState(false);
    const details = useAuthDetailStore((state) => state.detail);

    if (
        location.pathname.includes("time_reporting") &&
        !details?.features?.time_reporting?.display_in_main_menu
    ) {
        return (
            <Navigate
                to={"/denied"}
                state={{ path: location.pathname }}
                replace
            />
        );
    }
    if (
        location.pathname === "/time_reporting" &&
        !details?.features?.time_reporting?.activated_for_me &&
        details?.features?.time_reporting?.activated_for_me_as_manager
    ) {
        return (
            <Navigate
                to={ROUTES.time_reporting.team_tracking}
                state={{ path: location.pathname }}
                replace
            />
        );
    }
    // END OF ONLY FOR TIME REPORTING - change to another function LOGIC
    // const isLoged = user ? true : false;
    if (!user && !reload && !reloadOnce) {
        // get new token of the user there.
        setReload(true);
        refreshToken().then(() => {
            setReloadOnce(true);
            setReload(false);
        });
    }

    if (reload) return <LoaderPage label="Chargement des données de la page" />;
    if (!reload)
        return user ? (
            children
        ) : (
            <Navigate
                to={"/login"}
                replace
                state={{ path: location.pathname }}
            />
        );
};

PrivateRoute.propTypes = {
    children: PropTypes.element,
};

export default PrivateRoute;
