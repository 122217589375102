import React from "react";
import { Container, Duration, Hour } from "./index.style";
import { ArrowRightIcon, Flex } from "@fluentui/react-northstar";
import { CONFIG_SLOT } from "./constants";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";

/**
 * @typedef {Object} PropType
 * @property {("default"|"saved_or_sent"|"validated")} status
 * @property {string} start_time - the start time format 00:00:00
 * @property {string} end_time - the end time format 00:00:00
 *
 */

/**
 * Slot permet d'afficher un créneau
 * @param {PropType} PropType
 * @returns {ReactNode}
 */
const Slot = ({ status, start_time, end_time, duration }) => {
    const { color } = CONFIG_SLOT[status] ?? CONFIG_SLOT.default;
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const format_start_time = start_time?.slice(0, -3);
    const format_end_time = end_time?.slice(0, -3);
    return (
        <Container bg={color} hAlign="center" vAlign="center" column>
            <Flex space="between" vAlign="center" fill>
                <Hour content={format_start_time} />
                <ArrowRightIcon size="smaller" />
                <Hour content={format_end_time} />
            </Flex>
            <Flex>
                <Duration
                    content={`${t("v2.home.pills.duration")} : ${duration}`}
                />
            </Flex>
        </Container>
    );
};

export default Slot;
