import {
    Button,
    Flex,
    Loader,
    ParticipantAddIcon,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import CustomBreadCrumb from "../../../components/breadcrumb";
import { NAV_LIST } from "../constant";
import { ContainerFluid } from "../../../common/styles";
import { _getAllAgencys } from "../api";
import { useQuery } from "react-query";
import SubHeader from "../../../components/breadcrumb/SubHeader";
import PreOnboardingTask from "./DetailCollaborator";

export default function ToStartDetail() {
    const { isLoading, data, error } = useQuery(["agencys"], () =>
        _getAllAgencys()
    );
    const [TabView, setTabView] = useState(data ? data[0].id : null);

    const menuItems = data?.map((agency) => ({
        content: agency.name,
        value: agency.id,
        action_count: agency.action_count,
    }));
    if (isLoading) {
        return <Loader content="Loading ....." />;
    }
    console.log({ data });

    return (
        <ContainerFluid fill column>
            <CustomBreadCrumb navList={NAV_LIST} />
            <SubHeader
                fromOnboarding={true}
                menu={menuItems}
                setTabView={setTabView}
            />
            <PreOnboardingTask />
        </ContainerFluid>
    );
}
