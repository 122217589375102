import React from "react";
import { Container, ContainerFluid } from "../../../../common/styles";
import { Button, Flex, Text } from "@fluentui/react-northstar";

import SuiviUserDetail from "./SuiviUserDetail";
import HistoriqueSuiviCollab from "./HistoriqueSuiviCollab";

export default function ContentSuiviPage() {
    return (
        <Flex column>
            <Flex styles={{ gap: "32px" }}>
                {/* suivi user detail  */}
                <SuiviUserDetail />
                {/* historique  */}
                <HistoriqueSuiviCollab />
            </Flex>
            {/* footer actuin  */}
        </Flex>
    );
}
