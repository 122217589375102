import { Button, ExcelColorIcon, Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import SliderTabs from "../../../components/shared/SliderTabs";
import { PeopleCommunity16Regular } from "@fluentui/react-icons";
import DynamicTabContent from "./DynamicTabContent";
import { useQuery } from "react-query";
import { _getCollaboratorByAgence } from "../api";

const _renderTab = (TabView, data, AgencyId) => {
    const waiting_preonboarding_users = data?.waiting_preonboarding_users;
    const preonboarding_users = data?.preonboarding_users;
    const onboarding_users = data?.onboarding_users;
    const end_onboarding_users = data?.end_onboarding_users;
    const active_users = data?.active_users;
    switch (TabView) {
        case 0:
            return (
                <DynamicTabContent
                    AgencyId={AgencyId}
                    TabView={TabView}
                    users={
                        waiting_preonboarding_users?.length
                            ? waiting_preonboarding_users
                            : []
                    }
                />
            );
        case 1:
            return (
                <DynamicTabContent
                    AgencyId={AgencyId}
                    TabView={TabView}
                    users={preonboarding_users ?? []}
                />
            );
        case 2:
            return (
                <DynamicTabContent
                    TabView={TabView}
                    users={onboarding_users}
                    AgencyId={AgencyId}
                />
            );
        case 3:
            return (
                <DynamicTabContent
                    AgencyId={AgencyId}
                    TabView={TabView}
                    users={end_onboarding_users}
                />
            );
        case 4:
            return (
                <DynamicTabContent
                    AgencyId={AgencyId}
                    TabView={TabView}
                    users={active_users}
                />
            );
        default:
            return (
                <DynamicTabContent
                    AgencyId={AgencyId}
                    TabView={TabView}
                    users={waiting_preonboarding_users}
                />
            );
    }
};

export default function Content({ AgencyId }) {
    const [activeTab, setActiveTab] = useState(0);

    const { isLoading, data, error } = useQuery(
        ["getCollaboratorByAgence", AgencyId],
        () => _getCollaboratorByAgence(AgencyId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        }
    );

    const data2 = {
        company: {
            id: 10,
            name: "TechSphere",
        },
        agency: {
            id: 15,
            name: "Lyon",
        },
        waiting_preonboarding_users_count: 2,
        end_onboarding_users_count: 4,
        // à démarrer tab
        waiting_preonboarding_users: [
            {
                id: 105,
                username: "john.doe1@example.com",
                name: "John Doe",
                avatar: "",
                department: {
                    id: 5,
                    name: "Marketing",
                },
                start_date: "2024-09-01",
                probationary_period_end_date: "2024-12-01",
                task_count: 3,
                next_status_change: null,
                onboarding_status: {
                    status: "preonboarding",
                    status_text: "Pré-onboarding",
                },
            },
            {
                id: 110,
                username: "alice.smith@example.com",
                name: "Alice Smith",
                avatar: "",
                department: {
                    id: 6,
                    name: "Ressources Humaines",
                },
                start_date: "2024-08-15",
                probationary_period_end_date: "2024-11-15",
                task_count: 1,
                next_status_change: null,
                onboarding_status: {
                    status: "preonboarding",
                    status_text: "Pré-onboarding",
                },
            },
            {
                id: 110,
                username: "emily.white@example.com",
                name: "Emily White",
                avatar: "", // URL de l'avatar
                department: {
                    id: 8,
                    name: "Développement",
                },
                start_date: "2024-09-10",
                probationary_period_end_date: "2025-03-10",
                task_count: 4, // Nombre de tâches fictif
                next_status_change: "2024-11-10", // Date fictive du prochain changement de statut
                onboarding_status: {
                    status: "preonboarding", // Statut de pré-onboarding
                    status_text: "Pré-onboarding", // Texte du statut
                },
            },
        ],
        // préonboarding tab
        preonboarding_users: [
            {
                id: 120,
                username: "michael.brown@example.com",
                name: "Michael Brown",
                avatar: "",
                department: {
                    id: 7,
                    name: "Finance",
                },
                start_date: "2024-08-20",
                probationary_period_end_date: "2024-11-20",
                task_count: 4,
                next_status_change: null,
                onboarding_status: {
                    status: "preonboarding",
                    status_text: "Pré-onboarding",
                },
            },
            {
                id: 125,
                username: "emily.jones@example.com",
                name: "Emily Jones",
                avatar: "",
                department: {
                    id: 8,
                    name: "Ventes",
                },
                start_date: "2024-08-25",
                probationary_period_end_date: "2024-11-25",
                task_count: 2,
                next_status_change: null,
                onboarding_status: {
                    status: "preonboarding",
                    status_text: "Pré-onboarding",
                },
            },
        ],
        // en cours
        onboarding_users: [
            {
                id: 130,
                username: "chris.evans@example.com",
                name: "Chris Evans",
                avatar: "",
                department: {
                    id: 9,
                    name: "Développement",
                },
                start_date: "2024-08-30",
                probationary_period_end_date: "2024-11-30",
                task_count: 5,
                next_status_change: null,
                onboarding_status: {
                    status: "onboarding",
                    status_text: "En cours d'onboarding",
                },
            },
            {
                id: 135,
                username: "jessica.lee@example.com",
                name: "Jessica Lee",
                avatar: "",
                department: {
                    id: 10,
                    name: "Support Technique",
                },
                start_date: "2024-09-05",
                probationary_period_end_date: "2024-12-05",
                task_count: 3,
                next_status_change: null,
                onboarding_status: {
                    status: "onboarding",
                    status_text: "En cours d'onboarding",
                },
            },
            {
                id: 140,
                username: "michael.ross@example.com",
                name: "Michael Ross",
                avatar: "",
                department: {
                    id: 11,
                    name: "Gestion de Projet",
                },
                start_date: "2024-09-15",
                probationary_period_end_date: "2025-03-15",
                task_count: 2,
                next_status_change: null,
                onboarding_status: {
                    status: "onboarding",
                    status_text: "En cours d'onboarding",
                },
            },
            {
                id: 145,
                username: "natalie.johnson@example.com",
                name: "Natalie Johnson",
                avatar: "",
                department: {
                    id: 12,
                    name: "Analyse de Données",
                },
                start_date: "2024-10-01",
                probationary_period_end_date: "2025-04-01",
                task_count: 4,
                next_status_change: null,
                onboarding_status: {
                    status: "onboarding",
                    status_text: "En cours d'onboarding",
                },
            },
        ],
        // à clôturer tab
        end_onboarding_users: [
            {
                id: 140,
                username: "daniel.wilson@example.com",
                name: "Daniel Wilson",
                avatar: "",
                department: {
                    id: 11,
                    name: "Design",
                },
                start_date: "2024-07-15",
                probationary_period_end_date: "2024-10-15",
                task_count: 6,
                next_status_change: null,
                onboarding_status: {
                    status: "end_onboarding",
                    status_text: "Fin de l'onboarding",
                },
            },
            {
                id: 145,
                username: "sarah.connor@example.com",
                name: "Sarah Connor",
                avatar: "",
                department: {
                    id: 12,
                    name: "Gestion de projet",
                },
                start_date: "2024-07-25",
                probationary_period_end_date: "2024-10-25",
                task_count: 4,
                next_status_change: null,
                onboarding_status: {
                    status: "end_onboarding",
                    status_text: "Fin de l'onboarding",
                },
            },
            {
                id: 150,
                username: "olivia.martinez@example.com",
                name: "Olivia Martinez",
                avatar: "",
                department: {
                    id: 13,
                    name: "Communication",
                },
                start_date: "2024-08-01",
                probationary_period_end_date: "2024-11-01",
                task_count: 5,
                next_status_change: null,
                onboarding_status: {
                    status: "end_onboarding",
                    status_text: "Fin de l'onboarding",
                },
            },
        ],
        // suivi
        active_users: [
            {
                id: 150,
                username: "linda.hamilton@example.com",
                name: "Linda Hamilton",
                avatar: "",
                department: {
                    id: 13,
                    name: "Support Client",
                },
                start_date: "2023-11-01",
                probationary_period_end_date: "2024-02-01",
                task_count: 7,
                next_status_change: null,
                onboarding_status: {
                    status: "suivi",
                    status_text: "Suivi",
                },
            },
            {
                id: 155,
                username: "robert.patrick@example.com",
                name: "Robert Patrick",
                avatar: "",
                department: {
                    id: 14,
                    name: "Sécurité",
                },
                start_date: "2023-12-15",
                probationary_period_end_date: "2024-03-15",
                task_count: 9,
                next_status_change: null,
                onboarding_status: {
                    status: "suivi",
                    status_text: "Suivi",
                },
            },
            {
                id: 160,
                username: "emily.watson@example.com",
                name: "Emily Watson",
                avatar: "",
                department: {
                    id: 15,
                    name: "Marketing",
                },
                start_date: "2023-11-01",
                probationary_period_end_date: "2024-02-01",
                task_count: 7,
                next_status_change: null,
                onboarding_status: {
                    status: "suivi",
                    status_text: "Suivi",
                },
            },
            {
                id: 165,
                username: "david.carter@example.com",
                name: "David Carter",
                avatar: "",
                department: {
                    id: 16,
                    name: "Ventes",
                },
                start_date: "2023-10-10",
                probationary_period_end_date: "2024-01-10",
                task_count: 8,
                next_status_change: null,
                onboarding_status: {
                    status: "suivi",
                    status_text: "Suivi",
                },
            },
        ],
    };

    const TABS_ONBOARDING = {
        TO_START: {
            label: "A démarrer",
            description: data2?.waiting_preonboarding_users_count,
        },
        PRE_ONBOARDING: { label: "Pré-onboarding", description: 0 },
        IN_PROGRESS: { label: "En cours", description: 0 },
        TO_CLOSE: {
            label: "À clôturer",
            description: data2?.end_onboarding_users_count,
        },
        SUIVI: { label: "Suivi", description: 0 },
    };

    const TABS_ARRAY = [
        TABS_ONBOARDING.TO_START,
        TABS_ONBOARDING.PRE_ONBOARDING,
        TABS_ONBOARDING.IN_PROGRESS,
        TABS_ONBOARDING.TO_CLOSE,
        TABS_ONBOARDING.SUIVI,
    ];
    return (
        <Main column>
            <Flex space="between">
                <SliderTabs
                    defaultActiveIndex={0}
                    // tabs={TABS_ARRAY.map((tab) => tab.label)}
                    tabs={TABS_ARRAY.map((tab, index) => {
                        const { label, description } = tab;
                        return (
                            <Flex
                                key={index}
                                isActive={index === activeTab}
                                // gap="gap.smaller"
                            >
                                <Text content={label} />
                                {description !== 0 && (
                                    <SliderTabBadge content={description} />
                                )}
                            </Flex>
                        );
                    })}
                    activeTab={activeTab}
                    onChange={(tab) => setActiveTab(tab)}
                />

                <Button icon={<ExcelColorIcon />} flat content="Export" />
            </Flex>
            {_renderTab(activeTab, data, AgencyId)}
        </Main>
    );
}

const Main = styled(Flex)`
    background-color: #fff;
    padding: 24px 16px;

    height: 591px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const SliderTabBadge = styled(Text)`
    background-color: #ffb900;
    color: white;
    width: 24px;
    height: 24px;
    /* margin-left: 30%; */
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
