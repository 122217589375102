import React, { useMemo } from "react";
import styled from "styled-components";
import { Container } from "../../../common/styles";
import {
    AddIcon,
    Avatar,
    Divider,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import FooterActions from "./FooterActions";
import StatusBadge from "./StatusBadge";
import HeaderDates from "./HeaderDates";
import MyTasks from "../../DashboardTasks/MyTasks";
import { useMutation, useQuery } from "react-query";
import { _getAllAgencys, _startPreonboarding } from "../api";
import moment from "moment";

export default function CollaboratorTasks({ dataUserDetail }) {
    const { mutate: mutate_start_preonboarding, isLoading: is_loading_mutate } =
        useMutation((data) => _startPreonboarding(data));

    // const { isLoading, data, error, isFetching } = useQuery(
    //     ["agencys"],
    //     () => _getAllAgencys(),
    //     {
    //         refetchOnWindowFocus: false,
    //     }
    // );

    // const TabView = user?.TabView;
    // const AgencyId = user?.AgencyId;
    // const userDepartement = useMemo(() => user?.user?.department, []);
    // const startDate = useMemo(
    //     () => moment(user?.user?.start_date).format("DD/MM/YYYY"),
    //     []
    // );
    // const probationary_period_end_date = useMemo(
    //     () =>
    //         moment(user?.user?.probationary_period_end_date).format(
    //             "DD/MM/YYYY"
    //         ),
    //     []
    // );
    const startPreOnboarding = () => {
        return mutate_start_preonboarding();
    };
    const updateInformation = () => {
        // TabView === 3
        //     ? alert(" Information updated numéro 3")
        //     : alert(" Information not 3");
        // console.log({ TabView });
        return;
    };
    const interruptPreOnboarding = () => {
        alert(" Pre-onboarding interrupted");
    };
    const changeStartDate = () => {
        alert(" Start date changed");
    };

    // const agency = useMemo(
    //     () => data?.find((agency) => agency?.id === AgencyId),
    //     []
    // );

    console.log({ dataUserDetail });

    return (
        <Flex column>
            <Body column>
                <ContainerHeader vAlign="center" fill space="between">
                    <Flex gap="gap.small" vAlign="center">
                        <Avatar size="larger" name={dataUserDetail?.name} />
                        <Flex gap="gap.small" column>
                            <NameText content={dataUserDetail?.name} />
                            <Flex gap="gap.medium">
                                <AgencyDepartementFlex
                                    vAlign="center"
                                    // key={index}
                                    // gap="gap.small"
                                >
                                    <AddIcon />
                                    <Divider vertical size={1} />
                                    <DepartementName
                                        content={dataUserDetail?.agency?.name}
                                    />
                                </AgencyDepartementFlex>
                                <AgencyDepartementFlex
                                    vAlign="center"
                                    // key={index}
                                    // gap="gap.small"
                                >
                                    <AddIcon />
                                    <Divider vertical size={1} />
                                    <DepartementName
                                        content={
                                            dataUserDetail?.department?.name
                                        }
                                    />
                                </AgencyDepartementFlex>
                            </Flex>
                        </Flex>
                    </Flex>
                    {/* status badge */}
                    <Flex>
                        <StatusBadge
                            status={dataUserDetail?.onboarding_status_code}
                        />
                    </Flex>
                    {/* début de contrat et fin de période d'essai */}
                    <Flex>
                        <HeaderDates
                            contractStartDate={dataUserDetail?.start_date}
                            trialPeriodEndDate={
                                dataUserDetail?.probationary_period_end_date ||
                                "Non renseigné"
                            }
                        />
                    </Flex>
                </ContainerHeader>
                <TableContainer>
                    <MyTasks />
                </TableContainer>
            </Body>
            <ContainerFooter>
                <FooterActions
                    TabView={2}
                    startPreOnboarding={startPreOnboarding}
                    updateInformation={updateInformation}
                    interruptPreOnboarding={interruptPreOnboarding}
                    changeStartDate={changeStartDate}
                />
            </ContainerFooter>
        </Flex>
    );
}

const Body = styled(Container)`
    padding: 24px 16px;
    border-radius: 16px;
    background: #fff;
    margin-top: 24px;
    margin-bottom: 32px;
    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;
const ContainerFooter = styled(Container)`
    border-bottom: 1px solid #d1d1d1;
    background: #fff;
    box-shadow: 0px -1.2px 3.6px 0px rgba(0, 0, 0, 0.11),
        0px -6.4px 14.4px 0px rgba(0, 0, 0, 0.13);
    padding: 16px 24px;
    justify-content: flex-end;
    gap: 16px;
`;

const AgencyDepartementFlex = styled(Flex)`
    border-radius: 4px;
    background: #ebebeb;
    padding: 4px 8px;
`;

const TableContainer = styled(Container)`
    border-top: 4px solid #9299f7;
`;
const ContainerHeader = styled(Container)`
    margin-bottom: 42px;
`;
const DepartementName = styled(Text)`
    color: #424242;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const NameText = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
